import { useContext } from "react";

// contexts
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// types
import type { CollectionType } from "../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./PreviewCartCollection.module.scss";

type PreviewCartCollectionProps = {
  collection: CollectionType;
};

export function PreviewCartCollection({
  collection,
}: PreviewCartCollectionProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  return (
    <div className={styles.collection} key={collection.id}>
      <div className={styles.collectionInfo}>
        <>
          <div className={styles.collectionImageWrapper}>
            <img
              src={getImageUrl(collection.books[0].picture)}
              alt={`${collection.title}`}
            />

            {collection.books.length > 1 ? (
              <div className={styles.leftBooks}>
                + {collection.books.length - 1}
              </div>
            ) : null}
          </div>

          <p>{collection.title}</p>
        </>
      </div>

      <div className={styles.pricesWrapper}>
        <p>
          {getCurrencySymbol()}
          {collection.price}
        </p>
      </div>
    </div>
  );
}
