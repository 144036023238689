import { SVGProps } from "react";

const AccountIcon = ({
  className,
  color = "#000",
  width = 18,
  height = 19,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 9.448c-.963 0-1.786-.342-2.472-1.028C5.843 7.735 5.5 6.91 5.5 5.948s.343-1.786 1.028-2.472C7.214 2.791 8.038 2.448 9 2.448c.963 0 1.787.343 2.472 1.028.685.686 1.028 1.51 1.028 2.472 0 .963-.343 1.787-1.028 2.472-.685.686-1.51 1.028-2.472 1.028Zm-7 7v-2.45c0-.496.128-.951.383-1.367A2.55 2.55 0 0 1 3.4 11.68a12.988 12.988 0 0 1 2.756-1.018c.934-.226 1.881-.339 2.844-.339.963 0 1.91.113 2.844.34.933.225 1.852.564 2.756 1.017.423.218.762.535 1.017.951.255.416.383.871.383 1.367v2.45H2Z"
        fill="#fff"
        fillOpacity=".5"
      />
    </svg>
  );
};
export { AccountIcon };
