// consts
import { PATHS } from "../../route/route.controls";

//context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useAsideConsts() {
  const { i18n } = useContext(localesContext);
  const adminLinksData = [
    {
      to: PATHS.profile,
      title: t(i18n)`Profile`,
    },
    {
      to: PATHS.history,
      title: t(i18n)`History`,
    },
    {
      to: PATHS.myBooks,
      title: t(i18n)`My Books`,
    },
    {
      to: PATHS.myCollections,
      title: t(i18n)`My Collections`,
    },
    {
      to: PATHS.adminBooks,
      title: t(i18n)`Admin Panel`,
    },
  ];

  const userLinksData = [
    {
      to: PATHS.profile,
      title: t(i18n)`Profile`,
    },
    {
      to: PATHS.history,
      title: t(i18n)`History`,
    },
    {
      to: PATHS.myBooks,
      title: t(i18n)`My Books`,
    },
    {
      to: PATHS.myCollections,
      title: t(i18n)`My Collections`,
    },
  ];

  return {
    adminLinksData,
    userLinksData,
  };
}
