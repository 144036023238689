import { useContext } from "react";

// hooks
import { useBookFetch } from "./use-book";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// components
import Spinner from "../../../components/spinner/Spinner";
import { AddToCart } from "./add-to-cart/AddToCart";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// styles
import styles from "./BookScreen.module.scss";

//translations
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function BookScreen() {
  const { i18n } = useContext(localesContext);
  const { bookById } = useContext(booksContext);
  const { isBookLoading } = useBookFetch();

  const bookTitle = (
    genre:
      | "business"
      | "management"
      | "self-realization"
      | "motivation"
      | "Time management"
      | ""
  ) => {
    switch (genre) {
      case "business":
        return t(i18n)`Business`;

      case "management":
        return t(i18n)`Management`;

      case "self-realization":
        return t(i18n)`Self realization`;

      case "motivation":
        return t(i18n)`Motivation`;

      case "Time management":
        return t(i18n)`Time managementt`;
      default:
        return "";
    }
  };

  const tagTitle = (tagName: string) => {
    switch (tagName) {
      case "sponsored":
        return t(i18n)`sponsored`;

      case "authored":
        return t(i18n)`authored`;

      case "starred":
        return t(i18n)`starred`;

      default:
        return "";
    }
  };

  return (
    <section className={styles.container}>
      {isBookLoading ? (
        <div className={styles.spinner}>
          <Spinner color="dark" />
        </div>
      ) : bookById ? (
        <div className={styles.circleWrapper}>
          {/* Backgrodund Circles */}
          <div className={styles.violetCircle} />

          <div className={styles.redCircle} />

          <div className={styles.redDownCircle} />

          <div className={styles.content}>
            <h3>{bookById.title}</h3>
            <div className={styles.bookWrapper}>
              <div className={styles.bookMain}>
                <div className={styles.bookInfoWrapper}>
                  <div className={styles.imagesWrapper}>
                    <div className={styles.selectedImage}>
                      <img
                        src={getImageUrl(bookById.picture)}
                        alt={bookById.title}
                      />
                    </div>
                    <div className={styles.imagesListWrapper}>
                      <div className={styles.pictureWrapper}>
                        <img src={getImageUrl(bookById.picture)} alt={`book`} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.bookTypeWrapper}>
                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>
                        <Trans>Type</Trans>
                      </p>
                      <p>{bookTitle(bookById.category)}</p>
                    </div>

                    {bookById.tags.length ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>
                          <Trans>Tags</Trans>
                        </p>
                        <p>
                          {bookById.tags
                            .map((tag: string) => tagTitle(tag))
                            .join(", ")}
                        </p>
                      </div>
                    ) : null}

                    {bookById.source ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>
                          <Trans>Format included</Trans>
                        </p>
                        <p>✔ {bookById.source.extension}</p>
                      </div>
                    ) : null}

                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>
                        <Trans>Pages</Trans>
                      </p>
                      <p>
                        {bookById.pages} <Trans>Pages</Trans>
                      </p>
                    </div>
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={styles.description}>
                  <h6>
                    <Trans>Description</Trans>
                  </h6>
                  <p>{bookById.description}</p>
                </div>
              </div>

              <AddToCart book={bookById} />
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
