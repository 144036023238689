import { useContext } from "react";

//context
import { localesContext } from "../../../context/local-provider/LocalProvider";

// translation
import { t } from "@lingui/macro";

export function useHistoryUserConsts() {
  const { i18n } = useContext(localesContext);

  const statusData = {
    Completed: t(i18n)`Completed`,
    Declined: t(i18n)`Declined`,
    Error: t(i18n)`Error`,
    Refunded: t(i18n)`Refunded`,
    Pending: t(i18n)`Pending`,
  };

  return {
    statusData,
  };
}
