import { useContext } from "react";

// context
import { currencyContext } from "../../context/currency-provider/CurrencyProvider";

// hooks
import { usePreviewModalFetch } from "./use-preview-modal";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import { PreviewCartBook } from "./preview-cart-book/PreviewCartBook";
import { PreviewCartCollection } from "./preview-cart-collection/PreviewCartCollection";

// styles
import styles from "./PreviewCartModal.module.scss";

//translations
import { Trans } from "@lingui/macro";

type PreviewCartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleOpenCart: () => void;
};

export function PreviewCartModal({
  isOpen,
  onClose,
  handleOpenCart,
}: PreviewCartModalProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const { isCartLoading, filteredCart, totalPrice } =
    usePreviewModalFetch(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      variant="cart"
      width="340px"
    >
      <div className={styles.container}>
        {isCartLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div>
            {filteredCart ? (
              filteredCart.books.length || filteredCart.collections.length ? (
                <div>
                  {/* BOOKS */}
                  {filteredCart.books.map((book) => (
                    <PreviewCartBook book={book} key={book.id} />
                  ))}

                  {/* COLLECTIONS */}
                  {filteredCart.collections.map((collection) => (
                    <PreviewCartCollection
                      collection={collection}
                      key={collection.id}
                    />
                  ))}

                  <div className={styles.totalWrapper}>
                    <p>
                      <Trans>Total</Trans>
                    </p>
                    <p>
                      {totalPrice}
                      {getCurrencySymbol()}
                    </p>
                  </div>
                </div>
              ) : (
                <h6 className={styles.emptyCartText}>
                  <Trans>Your shopping cart is empty</Trans>
                </h6>
              )
            ) : null}
          </div>
        )}

        <div className={styles.buttonWrapper}>
          <Button width="auto" onClick={handleOpenCart}>
            <Trans>View cart</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
