// styles
import styles from "./Failed.screen.module.scss";

export function FailedScreen() {
  return (
    <div className={styles.container}>
      <h2 className={styles.text}>Data processing has failed.</h2>
    </div>
  );
}
