import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

// consts
import { CURRENCY } from "./CurrencyProvider.consts";

// types
import type {
  CurrencyContext,
  CurrencyProviderProps,
} from "./CurrencyProvider.types";

export const currencyContext = React.createContext({} as CurrencyContext);

export const CurrencyProvider: FunctionComponent<CurrencyProviderProps> = (
  props
) => {
  const { children } = props;

  const [currency, setCurrency] = useState<string>("");

  const getCurrency = () => {
    const localCurrencyString = localStorage.getItem(CURRENCY);

    if (localCurrencyString) {
      const localCurrency = JSON.parse(localCurrencyString) as string;
      return localCurrency;
    }

    return "EUR";
  };

  const setCurrentCurrency = (currency: string) => {
    setCurrency(currency);

    localStorage.setItem(CURRENCY, JSON.stringify(currency));
  };

  const getCurrencySymbol = (): string => {
    const currentCurrency = getCurrency();

    switch (currentCurrency) {
      case "EUR":
        return "€";
      case "USD":
        return "$";
      case "UAH":
        return "₴";
      case "GBP":
        return "£";
      default:
        return "€";
    }
  };

  const contextValue = useMemo(
    () => ({
      currency,
      getCurrency,
      setCurrentCurrency,
      getCurrencySymbol,
    }),
    [currency, getCurrency, setCurrentCurrency]
  );

  return (
    <currencyContext.Provider value={contextValue}>
      {children}
    </currencyContext.Provider>
  );
};
