import { I18nProvider } from "@lingui/react";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

// types
import { LocalesContext, Props } from "./LocaleProvider.types";

export const localesContext = createContext<LocalesContext>(undefined!);

const LocalesProvider: React.FC<Props> = ({ i18n, children }) => {
  const storedLocale = localStorage.getItem("locale") || "en";
  const [locale, setLocale] = useState(storedLocale);

  const changeLanguage = useCallback(
    (newLocale: string) => {
      setLocale(newLocale);
      localStorage.setItem("locale", newLocale);
      i18n.activate(newLocale);
    },
    [i18n]
  );

  useEffect(() => {
    i18n.activate(locale);
  }, [i18n, locale]);

  const contextValue = useMemo(
    () => ({
      i18n,
      locale,
      changeLanguage,
    }),
    [i18n, locale, changeLanguage]
  );

  return (
    <localesContext.Provider value={contextValue}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </localesContext.Provider>
  );
};

export default LocalesProvider;
