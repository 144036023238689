import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// components
import App from "./App";

// context
import { CurrencyProvider } from "./context/currency-provider/CurrencyProvider";
import { ErrorProvider } from "./context/error-provider/ErrorProvider";
import { ApiProvider } from "./context/api-provider/ApiProvider";
import { UserProvider } from "./context/user-provider/UserProvider";
import { SessionProvider } from "./context/session-provider/SessionProvider";
import { CouponProvider } from "./context/coupon-provider/CouponProvider";
import { OrderProvider } from "./context/order-provider/OrderProvider";
import { CartProvider } from "./context/cart-provider/CartProvider";
import { BooksProvider } from "./context/books-provider/BooksProvider";
import { StorageProvider } from "./context/storage-provider/StorageProvider";
import { OpenBankingProvider } from "./context/open-banking-provider/OpenBankingProvider";
import { PaymentProvider } from "./context/payement-provider/PaymentProvider";
import { CollectionsProvider } from "./context/collections-provider/CollectionsProvider";

// styles
import "./styles/globals.scss";

//translation
import {
  getI18nInstance,
  validateLanguage,
} from "./context/local-provider/LocaleProvider.helper";
import LocalesProvider from "./context/local-provider/LocalProvider";

// styles
import "./styles/globals.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const storedLocale = localStorage.getItem("locale") || "en";

const language = validateLanguage(storedLocale);

const i18n = getI18nInstance(language);

root.render(
  // <React.StrictMode>
  <LocalesProvider i18n={i18n}>
    <CurrencyProvider>
      <BrowserRouter>
        <ErrorProvider>
          <SessionProvider>
            <ApiProvider>
              <UserProvider>
                <BooksProvider>
                  <CouponProvider>
                    <CartProvider>
                      <CollectionsProvider>
                        <OrderProvider>
                          <OpenBankingProvider>
                            <PaymentProvider>
                              <StorageProvider>
                                <App />
                              </StorageProvider>
                            </PaymentProvider>
                          </OpenBankingProvider>
                        </OrderProvider>
                      </CollectionsProvider>
                    </CartProvider>
                  </CouponProvider>
                </BooksProvider>
              </UserProvider>
            </ApiProvider>
          </SessionProvider>
        </ErrorProvider>
      </BrowserRouter>
    </CurrencyProvider>
  </LocalesProvider>
  // </React.StrictMode>
);
