import React, { useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// consts
import { PATHS } from "../../route/route.controls";
import { useAsideConsts } from "./use-aside.consts";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";

// styles
import styles from "./Aside.module.scss";

//translation
import { Trans } from "@lingui/macro";

type AsideProps = {
  role?: "admin" | "user" | null;
};

export function Aside({ role }: AsideProps) {
  const { error } = useContext(errorContext);
  const { logOut } = useContext(userContext);
  const { pathname: currentURL } = useLocation();

  const { adminLinksData, userLinksData } = useAsideConsts();

  const isAdminRole = role === "admin";

  const handleLogOut = useCallback(async () => {
    try {
      await logOut();
    } catch (e) {
      error(e);
    }
  }, [logOut, error]);

  const linksData = isAdminRole ? adminLinksData : userLinksData;

  const isActiveLink = (link: string) => link === currentURL;

  return (
    <aside className={styles.aside}>
      <div className={styles.linksWrapper}>
        {linksData.map((link, index) => (
          <Link
            to={link.to}
            key={index}
            className={isActiveLink(link.to) ? styles.activeLink : styles.link}
          >
            {link.title}
          </Link>
        ))}
        <Link to={PATHS.index} className={styles.link} onClick={handleLogOut}>
          <Trans>Log Out</Trans>
        </Link>
      </div>
    </aside>
  );
}
