import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// context
export function useCollectionsFetch() {
  const { error } = useContext(errorContext);
  const { getCollections } = useContext(collectionsContext);

  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const collectionsAdminFetch = async () => {
    try {
      setIsCollectionsLoading(true);

      await getCollections(30, itemOffset, true);
    } catch (err) {
      error(err);
    } finally {
      setIsCollectionsLoading(false);
    }
  };

  useEffect(() => {
    collectionsAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset]);

  return {
    isCollectionsLoading,
    currentPage,
    setCurrentPage,
    setItemOffset,
  };
}
