import React, { SVGProps } from "react";

const SuccessIcon = ({
  className,
  color = "#699F4C",
  width = 60,
  height = 60,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 60 60"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 60C24.0666 60 18.2664 58.2406 13.3329 54.9441C8.39944 51.6477 4.55429 46.9623 2.28366 41.4805C0.0130296 35.9987 -0.581112 29.9667 0.576444 24.1473C1.734 18.3279 4.59126 12.9824 8.78683 8.78682C12.9824 4.59124 18.3279 1.73402 24.1473 0.57646C29.9667 -0.581097 35.9988 0.0129845 41.4806 2.28361C46.9623 4.55424 51.6477 8.39942 54.9441 13.3329C58.2406 18.2664 60 24.0666 60 30C60 37.9565 56.8393 45.5871 51.2132 51.2132C45.5871 56.8393 37.9565 60 30 60ZM13.014 36.765C12.3689 36.1128 12.007 35.2324 12.007 34.315C12.007 33.3976 12.3689 32.5172 13.014 31.865L14.7801 30.109C15.0804 29.806 15.4385 29.5663 15.8332 29.4044C16.2279 29.2424 16.6512 29.1614 17.0778 29.1662C17.5045 29.1709 17.9258 29.2613 18.3168 29.4321C18.7078 29.6028 19.0605 29.8503 19.354 30.16L22.474 33.397C22.6228 33.554 22.802 33.679 23.0006 33.7644C23.1993 33.8498 23.4133 33.8939 23.6295 33.8939C23.8458 33.8939 24.0598 33.8498 24.2585 33.7644C24.4571 33.679 24.6362 33.554 24.785 33.397L40.685 17.007C40.9806 16.6951 41.3363 16.4463 41.7306 16.2754C42.1248 16.1045 42.5496 16.0151 42.9793 16.0126C43.409 16.0101 43.8347 16.0945 44.231 16.2607C44.6272 16.427 44.9857 16.6717 45.285 16.98L47 18.714C47.6294 19.3636 47.9813 20.2325 47.9813 21.137C47.9813 22.0415 47.6294 22.9104 47 23.56L25.891 45.011C25.5964 45.3159 25.244 45.559 24.8542 45.7259C24.4645 45.8928 24.0453 45.9802 23.6213 45.983C23.1973 45.9858 22.7771 45.9039 22.3852 45.7422C21.9932 45.5804 21.6376 45.342 21.339 45.041L13.014 36.765Z"
        fill={color}
      />
    </svg>
  );
};
export { SuccessIcon };
