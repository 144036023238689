import React, { useCallback, useContext } from "react";

// components
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Textarea from "../../../../components/textarea/Textarea";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { Select } from "../../../../components/select/Select";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useCollectionAdminForm } from "../use-collections-admin";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// types
import type { CollectionType } from "../../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./CollectionAdminModal.module.scss";

//translations
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

type CollectionAdminModalProps = {
  collection: CollectionType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function CollectionAdminModal({
  collection,
  isOpen,
  onClose,
}: CollectionAdminModalProps) {
  const { i18n } = useContext(localesContext);

  const { error } = useContext(errorContext);

  const {
    collectionFormData,
    isDisabledSubmitButton,
    tagsCollectionsAdminOptions,
    booksCollectionsAdminOptions,
    handleChangeCollectionData,
    handleChangeSelectData,
    onSubmit,
  } = useCollectionAdminForm(isOpen, collection);

  const { width } = useWindowSize();

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal
    ? t(i18n)`Create new collection`
    : t(i18n)`Edit collection`;

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={modalHeader}
      width={width > 767 ? "65%" : "97%"}
    >
      <form className={styles.form}>
        <div className={styles.inputWrapper}>
          <Input
            label={t(i18n)`Title`}
            name="title"
            value={collectionFormData.title}
            onChange={handleChangeCollectionData}
            validation={{
              error: collectionFormData.title.length >= 64,
              message:
                collectionFormData.title.length >= 64
                  ? t(i18n)`Title is too long. Please limit to 64 characters.`
                  : "",
            }}
          />

          <Input
            label={t(i18n)`Price`}
            name="price"
            value={collectionFormData.price || ""}
            onChange={handleChangeCollectionData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Textarea
            id="description"
            label={t(i18n)`Description`}
            name="description"
            variant="admin"
            value={collectionFormData.description}
            onChange={handleChangeCollectionData}
          />
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.selectWrapper}>
            <Select
              name="ids"
              placeholder={t(i18n)`Books`}
              options={booksCollectionsAdminOptions}
              value={booksCollectionsAdminOptions.filter((option) =>
                collectionFormData.ids.includes(option.value)
              )}
              isMulti
              onChange={handleChangeSelectData}
            />
          </div>

          <div className={styles.selectWrapper}>
            <Select
              name="tags"
              placeholder={t(i18n)`Tags`}
              options={tagsCollectionsAdminOptions}
              value={tagsCollectionsAdminOptions.filter((option) =>
                collectionFormData.tags.includes(option.value)
              )}
              isMulti
              onChange={handleChangeSelectData}
            />
          </div>
        </div>

        <div className={styles.inputWrapper}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="Is "
              name="isPublished"
              value="1"
              checked={collectionFormData.isPublished}
              onChange={handleChangeCollectionData}
            />
            <p className={styles.checkboxText}>
              <Trans>Is Published</Trans>
            </p>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            onClick={onClose}
            variant="outline"
            className={styles.cancelButton}
          >
            <Trans>Cancel</Trans>
          </Button>

          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isDisabledSubmitButton}
          >
            <Trans>Submit</Trans>
          </Button>
        </div>
      </form>
    </Modal>
  );
}
