import { useCallback, useContext } from "react";

// translations
import { t } from "@lingui/macro";

// contexts
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";

// helpers
import { getImageUrl } from "../../../../helpers/getImageUrl";
import { autoDownload } from "../../../../helpers/autoDownload";

// icons
import { DownloadIcon } from "../../../../assets/icons/DownloadIcon";

// types
import type { CollectionType } from "../../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./MyCollection.module.scss";

type MyBookProps = {
  collection: CollectionType;
};

export default function MyCollection({ collection }: MyBookProps) {
  const { error, success } = useContext(errorContext);
  const { i18n } = useContext(localesContext);
  const { downloadPDF } = useContext(storageContext);

  const handleDownload = useCallback(
    async (key: string | null | undefined, title: string) => {
      if (!key) {
        error(t(i18n)`Unable to download the book at this time.`);
        return;
      }

      try {
        const file = await downloadPDF(key);

        if (file) {
          autoDownload(file, title, "application/pdf");
        }

        success(t(i18n)`Book was successfully downloaded`);
      } catch (e) {
        error(e);
      }
    },
    [downloadPDF]
  );

  return (
    <div key={collection.id} className={styles.collection}>
      <p className={styles.collectionTitle}>{collection.title}</p>

      <div className={styles.booksWrapper}>
        {collection.books.map((book) => (
          <div className={styles.book} key={book.id}>
            <div className={styles.imageWrapper}>
              <img
                src={getImageUrl(book?.picture)}
                alt={book.title}
                loading="lazy"
                className={styles.image}
              />
            </div>
            <div className={styles.title}>{book.title}</div>
            <div className={styles.downloadWrapper}>
              {book.source ? (
                <DownloadIcon
                  onClick={() => handleDownload(book.source?.key, book.title)}
                  className={styles.downloadIcon}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
