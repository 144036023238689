import { useContext } from "react";

// contexts
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./PreviewCartBook.module.scss";

type PreviewCartBookProps = {
  book: BookType;
};

export function PreviewCartBook({ book }: PreviewCartBookProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  return (
    <div className={styles.book} key={book.id}>
      <div className={styles.bookInfo}>
        <img src={getImageUrl(book.picture)} alt={`${book.title}`} />
        <div>
          <p>{book.title}</p>
        </div>
      </div>

      <div className={styles.pricesWrapper}>
        <>
          {book.discountPrice ? (
            <p>
              {getCurrencySymbol()}
              {book.discountPrice}
            </p>
          ) : null}
          <div className={book.discountPrice ? styles.diagonalLine : ""}>
            <p className={styles.oldPrice}>
              {getCurrencySymbol()}
              {book.price}
            </p>
          </div>
        </>
      </div>
    </div>
  );
}
