import { useCallback, useContext } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// contexts
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../../../../components/button/Button";

// types
import type { CollectionType } from "../../../../../context/collections-provider/CollectionsProvider.types";

// consts
import { PATHS } from "../../../../../route/route.controls";

// styles
import styles from "./Collection.module.scss";
import { getImageUrl } from "../../../../../helpers/getImageUrl";

//translations
import { Trans } from "@lingui/macro";

type CollectionProps = {
  collection: CollectionType;
};

export function Collection({ collection }: CollectionProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const handleOpenCurrentCollection = useCallback(
    (collectionId: string) => {
      const generatedLink = generatePath(PATHS.collection, {
        collectionId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  const otherBooks = (booksLenght: number) => {
    const restBooks = booksLenght - 4;

    if (restBooks > 1) {
      return `+ ${collection.books.length - 4}`;
    }

    return "+ 1";
  };

  return (
    <div
      className={styles.bookContainer}
      onClick={() => handleOpenCurrentCollection(collection.id)}
    >
      <div className={styles.imagesWrapper}>
        {collection.books.slice(0, 4).map((book, index) => (
          <div className={styles.imageWrapper} key={book.id}>
            <img src={getImageUrl(book.picture)} alt="course" />

            {collection.books.length > 4 && index === 3 ? (
              <div className={styles.otherBooks}>
                <p>{otherBooks(collection.books.length)}</p>
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div className={styles.info}>
        <p className={styles.title}>{collection.title}</p>
        <p className={styles.description}>
          <Trans>Collection</Trans>
        </p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button width="auto">
          <Trans>Buy</Trans>
        </Button>
        <div>
          <div className={styles.pricesWrapper}>
            {collection.price ? (
              <p>
                {getCurrencySymbol()}
                {collection.price}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
