import { useContext } from "react";

// translations
import { Trans } from "@lingui/macro";

// context
import { userContext } from "../../../context/user-provider/UserProvider";

// hooks
import { useMyBooksFetch } from "./use-my-books";

// components
import Spinner from "../../../components/spinner/Spinner";
import MyBook from "./my-book/MyBook";

// types
import type { OrderType } from "../../../context/order-provider/OrderProvider.types";

// styles
import styles from "./MyBooksScreen.module.scss";

export function MyBooksScreen() {
  const { userData } = useContext(userContext);

  const { isMyBooksLoading } = useMyBooksFetch();

  const sortedUserData = (orders: OrderType[]) => {
    return orders.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA;
    });
  };

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        <Trans>My Books</Trans>
      </h3>

      {isMyBooksLoading ? (
        <Spinner />
      ) : userData && userData.orders?.length ? (
        <div className={styles.booksWrapper}>
          {sortedUserData(userData.orders).map((order) =>
            order.books
              ?.reverse()
              .map((book) => <MyBook book={book} key={book.id} />)
          )}
        </div>
      ) : (
        <h4 className={styles.title}>
          <Trans>Regrettably, there are currently no books.</Trans>
        </h4>
      )}
    </section>
  );
}
