import { useCallback, useContext, useMemo, useState } from "react";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";
import { localesContext } from "../../context/local-provider/LocalProvider";

// consts
import { INIT_TEACHER_FORM_DATA } from "./TeacherForm.consts";

// types
import type { CourseFormType } from "./../../context/user-provider/UserProvider.types";

//translations
import { t } from "@lingui/macro";

export function useTeacherForm() {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { teacherSkillsUp } = useContext(userContext);

  const [teacherFormData, setTeacherFormData] = useState<CourseFormType>(
    INIT_TEACHER_FORM_DATA,
  );

  const handleChangeTeacherFormData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      const { name, value } = e.target;

      setTeacherFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setTeacherFormData],
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        await teacherSkillsUp(teacherFormData);

        success(
          t(
            i18n,
          )`Your application has been submitted! Our specialist will contact you shortly.`,
        );
        setTeacherFormData(INIT_TEACHER_FORM_DATA);
      } catch (e) {
        error(e);
      }
    },
    [
      teacherFormData,
      i18n,
      teacherSkillsUp,
      success,
      setTeacherFormData,
      error,
    ],
  );

  const isDisabledSubmitButton = useMemo(
    () =>
      !teacherFormData.email ||
      !teacherFormData.firstName ||
      !teacherFormData.lastName ||
      !teacherFormData.socialMedia ||
      !teacherFormData.message,
    [teacherFormData],
  );

  return {
    teacherFormData,
    isDisabledSubmitButton,
    handleChangeTeacherFormData,
    onSubmit,
  };
}
