import * as React from "react";
import PureModal from "react-pure-modal";
import classnames from "classnames";

// icons
import { CloseIcon } from "../../assets/icons/CloseIcon";

// styles
import "react-pure-modal/dist/react-pure-modal.min.css";
import styles from "./Modal.module.scss";

type Props = {
  children: JSX.Element;
  replace?: boolean;
  variant?: "default" | "cart";
  className?: string;
  header?: JSX.Element | string;
  footer?: JSX.Element | string;
  scrollable?: boolean;
  draggable?: boolean;
  width?: string;
  isOpen: boolean;
  onClose: Function;
  closeButton?: JSX.Element | string;
  closeButtonPosition?: string;
  portal?: boolean;
};

function Modal({
  header = "",
  footer = "",
  replace = false,
  variant = "default",
  scrollable = true,
  draggable = false,
  portal = false,
  width = "fit-content",
  closeButton = <CloseIcon width={18} height={18} color="#fff" />,
  closeButtonPosition = "header",
  className = "",
  children,
  ...props
}: Props) {
  const isCartVariant = variant === "cart";

  return (
    <PureModal
      width={width}
      header={
        header ? (
          <div className={styles.circleWrapper}>
            <div className={styles.violetCircle} />

            <div className={styles.redCircle} />

            <div className={styles.header}>{header}</div>
          </div>
        ) : (
          ""
        )
      }
      footer={footer}
      replace={replace}
      scrollable={scrollable}
      draggable={draggable}
      portal={portal}
      closeButtonPosition={"header"}
      closeButton={!isCartVariant ? closeButton : ""}
      className={classnames(
        className,
        styles.modal,
        isCartVariant ? styles.cartModal : ""
      )}
      {...props}
    >
      {children}
    </PureModal>
  );
}

export default Modal;
