// consts
import { PATHS } from "../../route/route.controls";

//context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useAdminLayoutConsts() {
  const { i18n } = useContext(localesContext);
  const ADMIN_TABS = [
    {
      title: t(i18n)`Books`,
      to: PATHS.adminBooks,
    },
    {
      title: t(i18n)`Collections`,
      to: PATHS.adminCollections,
    },
    {
      title: t(i18n)`Coupons`,
      to: PATHS.adminCoupons,
    },
  ];

  return {
    ADMIN_TABS,
  };
}
