import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// translations
import { t, Trans } from "@lingui/macro";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// hooks
import { useCollectionFetch } from "./use-collection";

// context
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Spinner from "../../../components/spinner/Spinner";
import { AddToCart } from "./add-to-cart/AddToCart";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// types
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./CollectionScreen.module.scss";

export function CollectionScreen() {
  const { i18n } = useContext(localesContext);
  const { collectionById } = useContext(collectionsContext);
  const { isCollectionLoading } = useCollectionFetch();

  const navigate = useNavigate();

  const generatedLink = (bookId: string) => {
    return generatePath(PATHS.book, {
      bookId,
    });
  };

  const [selectedBook, setSelectedBook] = useState<BookType | null>(null);

  const [showedBooks, setShowedBooks] = useState<number>(5);

  const otherBooks = (booksLenght: number) => {
    if (collectionById) {
      const restBooks = booksLenght - showedBooks;

      if (restBooks > 1) {
        return `+ ${collectionById.books.length - showedBooks}`;
      }

      return "+ 1";
    }
  };

  const tagTitle = (tagName: string) => {
    switch (tagName) {
      case "sponsored":
        return t(i18n)`sponsored`;

      case "authored":
        return t(i18n)`authored`;

      case "starred":
        return t(i18n)`starred`;

      default:
        return "";
    }
  };

  useEffect(() => {
    if (collectionById?.books) {
      return setSelectedBook(collectionById.books[0]);
    }
  }, [collectionById]);

  return (
    <section className={styles.container}>
      {isCollectionLoading ? (
        <div className={styles.spinner}>
          <Spinner color="dark" />
        </div>
      ) : collectionById ? (
        <div className={styles.circleWrapper}>
          {/* Backgrodund Circles */}
          <div className={styles.violetCircle} />

          <div className={styles.redCircle} />

          <div className={styles.redDownCircle} />

          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <p onClick={() => navigate(PATHS.collections)}>
                <Trans>Collections</Trans>
              </p>
              <h3>{collectionById.title}</h3>
            </div>

            <div className={styles.bookWrapper}>
              <div className={styles.bookMain}>
                <div className={styles.bookInfoWrapper}>
                  <div className={styles.imagesWrapper}>
                    <div className={styles.selectedImage}>
                      <img
                        src={getImageUrl(selectedBook?.picture)}
                        alt={collectionById.title}
                      />
                    </div>
                    <div className={styles.imagesListWrapper}>
                      {collectionById.books.length
                        ? collectionById.books
                            .slice(0, showedBooks)
                            .map((book, index) => (
                              <div
                                key={book.id}
                                className={styles.pictureWrapper}
                              >
                                <img
                                  src={getImageUrl(book.picture)}
                                  alt={`${index} book`}
                                  onClick={() => setSelectedBook(book)}
                                />

                                {collectionById.books.length > showedBooks &&
                                index === showedBooks - 1 ? (
                                  <div
                                    className={styles.otherBooks}
                                    onClick={() =>
                                      setShowedBooks(
                                        collectionById.books.length
                                      )
                                    }
                                  >
                                    <p>
                                      {otherBooks(collectionById.books.length)}
                                    </p>
                                  </div>
                                ) : null}
                                {selectedBook === book ? (
                                  <div className={styles.pictureDivider} />
                                ) : null}
                              </div>
                            ))
                        : null}
                    </div>
                  </div>
                  <div className={styles.bookTypeWrapper}>
                    <div className={styles.bookType}>
                      <p className={styles.bookTypeText}>
                        <Trans>Collection include</Trans>
                      </p>
                      <p>
                        {collectionById.books.length} <Trans>Books</Trans>
                      </p>
                    </div>

                    {collectionById.tags.length ? (
                      <div className={styles.bookType}>
                        <p className={styles.bookTypeText}>
                          <Trans>Tags</Trans>
                        </p>
                        <p>
                          {collectionById.tags
                            .map((tag: string) => tagTitle(tag))
                            .join(", ")}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={styles.description}>
                  <h6>
                    <Trans>Description</Trans>
                  </h6>
                  <p className={styles.booksTitle}>
                    <Trans>Collection include:</Trans>
                  </p>

                  <ul>
                    {collectionById.books.map((book) => (
                      <li
                        onClick={() => navigate(generatedLink(book.id))}
                        key={book.id}
                        className={styles.bookTitle}
                      >
                        {book.title}
                      </li>
                    ))}
                  </ul>

                  <p>{collectionById.description}</p>
                </div>
              </div>

              <AddToCart collection={collectionById} />
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
