import { useCallback, useContext } from "react";

// components
import Button from "../../../../components/button/Button";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";
import type { CollectionType } from "../../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./AddToCart.module.scss";

//translations
import { Trans } from "@lingui/macro";

type AddToCartProps = {
  book?: BookType;
  collection?: CollectionType;
};

export function AddToCart({ book, collection }: AddToCartProps) {
  const { getCurrencySymbol } = useContext(currencyContext);
  const { addBookToCart, addCollectionToCart } = useContext(cartContext);

  const isBooksAddToCart = !!book;

  const isCollectionsAddToCart = !!collection;

  const handleAddBookToCart = useCallback(
    (selectedBook: BookType) => {
      addBookToCart(selectedBook);
    },
    [addBookToCart]
  );

  const handleAddCollectionToCart = useCallback(
    (selectedCollection: CollectionType) => {
      addCollectionToCart(selectedCollection);
    },
    [addCollectionToCart]
  );

  return (
    <div className={styles.container}>
      <div className={styles.priceWrapper}>
        <div className={styles.newPriceWrapper}>
          <p>
            <Trans>Price</Trans>
          </p>
          {isBooksAddToCart ? (
            <p className={styles.newPrice}>
              {getCurrencySymbol()}
              {book.discountPrice ? book.discountPrice : book.price}
            </p>
          ) : null}

          {isCollectionsAddToCart ? (
            <p className={styles.newPrice}>
              {getCurrencySymbol()}
              {collection.price}
            </p>
          ) : null}
        </div>

        {isBooksAddToCart ? (
          book.discountPrice ? (
            <div className={styles.oldPriceWrapper}>
              <p>
                <Trans>Last price</Trans>
              </p>
              <div className={styles.diagonalLine}>
                <p className={styles.oldPrice}>
                  {getCurrencySymbol()}
                  {book.price}
                </p>
              </div>
            </div>
          ) : null
        ) : null}
      </div>

      {isBooksAddToCart ? (
        <Button onClick={() => handleAddBookToCart(book)}>
          <Trans>Add to cart</Trans>
        </Button>
      ) : null}

      {isCollectionsAddToCart ? (
        <Button onClick={() => handleAddCollectionToCart(collection)}>
          <Trans>Add to cart</Trans>
        </Button>
      ) : null}
    </div>
  );
}
