import { SVGProps } from "react";

const DownloadIcon = ({
  className,
  color = "#1C1B1F",
  width = 16,
  height = 17,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m7.999 12.6-5-5 1.4-1.45 2.6 2.6V.6h2v8.15l2.6-2.6 1.4 1.45-5 5Zm-6 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1-.001 14.6v-3h2v3h12v-3h2v3c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 14 16.6h-12Z"
        fill={color}
      />
    </svg>
  );
};
export { DownloadIcon };
