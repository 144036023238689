import React from "react";

// images
import present_src from "../../../../assets/images/present.png";

// styles
import styles from "./Discount.module.scss";

//translations
import { Trans } from "@lingui/macro";

export function Discount() {
  return (
    <section className={styles.discountSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountInfo}>
          <p>
            <Trans>Come in and Join us</Trans>
          </p>
          <h1>
            <Trans>Get</Trans>{" "}
            <span>
              <Trans>20% Off</Trans>
            </span>
          </h1>
          <p>
            <Trans>for all products</Trans>
          </p>
        </div>
        <div className={styles.discountImage}>
          <img src={present_src} alt="present" />
        </div>
      </div>
    </section>
  );
}
