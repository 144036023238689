import { CartProduct } from "../context/cart-provider/CartProvider.types";

export const validateAndClearCartProduct = (key: string): boolean => {
  const storedValue = localStorage.getItem(key);

  if (!storedValue) {
    return false;
  }

  try {
    const parsedValue: CartProduct = JSON.parse(storedValue);

    const hasValidBooks =
      Array.isArray(parsedValue.books) &&
      parsedValue.books.every((item) => typeof item === "string");
    const hasValidCollections =
      Array.isArray(parsedValue.collections) &&
      parsedValue.collections.every((item) => typeof item === "string");

    const isValidStructure = hasValidBooks && hasValidCollections;

    if (!isValidStructure) {
      localStorage.removeItem(key);
    }

    return isValidStructure;
  } catch (error) {
    localStorage.removeItem(key);
    return false;
  }
};
