import { useCallback, useContext } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// contexts
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../../../../components/button/Button";

// helpers
import { getImageUrl } from "../../../../../helpers/getImageUrl";

// types
import { BookType } from "../../../../../context/books-provider/BooksProvider.types";

// consts
import { PATHS } from "../../../../../route/route.controls";

// styles
import styles from "./Book.module.scss";

//translations
import { Trans } from "@lingui/macro";

type BookProps = {
  book: BookType;
};

export function Book({ book }: BookProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const handleOpenCurrentBook = useCallback(
    (bookId: string) => {
      const generatedLink = generatePath(PATHS.book, {
        bookId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  return (
    <div
      className={styles.bookContainer}
      onClick={() => handleOpenCurrentBook(book.id)}
    >
      <div className={styles.imageWrapper}>
        <img src={getImageUrl(book?.picture)} alt="course" />
      </div>
      <div className={styles.info}>
        <p className={styles.description}>{book.title}</p>
        <p className={styles.author}>
          <Trans>by</Trans> {book.author}
        </p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button width="auto">
          <Trans>Buy</Trans>
        </Button>
        <div>
          <div className={styles.pricesWrapper}>
            {book.discountPrice ? (
              <p>
                {getCurrencySymbol()}
                {book.discountPrice}
              </p>
            ) : null}
            <div className={book.discountPrice ? styles.diagonalLine : ""}>
              <p className={styles.oldPrice}>
                {getCurrencySymbol()}
                {book.price}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
