import { AxiosError } from "axios";

// types
import type {
  ErrorResponse,
  InputPayload,
  Payload,
} from "./ErrorProvider.types";

class ExtendedErrorClass extends Error {
  payload: Payload | InputPayload;

  constructor(messageOrError: string | Error, payload: Payload = {}) {
    const message =
      messageOrError instanceof Error ? messageOrError.message : messageOrError;
    const rawStack =
      messageOrError instanceof Error ? messageOrError.stack : undefined;
    super(message);
    this.name = this.constructor.name;
    const stack = rawStack?.replace(/^.+\n/, `${this.name}: ${this.message}\n`);
    this.payload = payload;

    if (stack !== "" && stack !== undefined) {
      this.stack = stack;
    }
  }

  /**
   * Update place where error happens
   *
   * @param scope set specific scope (parent function name for example) for error
   * @returns updated error
   */

  /**
   * Simplify errors logging. Call automatically
   * @returns error converted to string representation
   */
  toString(): string {
    return `${this.name}: ${this.message} ${JSON.stringify(this.payload)}`;
  }

  /**
   * Simplify errors logging. Call automatically
   * @returns error converted to string representation
   */
  toJSON(): string {
    return JSON.stringify({
      name: this.name,
      message: this.message,
      payload: this.payload,
    });
  }
}

/**
 * Class for API validation errors
 */
export class ValidationError extends ExtendedErrorClass {}

/**
 * Class for API access errors
 */
export class PermissionError extends ExtendedErrorClass {}

/**
 * Class for API business logic errors
 */
export class ApiError extends ExtendedErrorClass {}

/**
 * Class for fatal errors when we should show user critical error
 */
export class FatalError extends ExtendedErrorClass {}

/**
 * Class for user's input errors
 */

export class DefaultError extends AxiosError {
  payload: Payload;
  constructor(error: AxiosError) {
    const customError = error.response?.data as ErrorResponse;

    super(`Default error: ${customError.message}`);
    this.name = "DefaultError";
    this.message = customError.message;
    this.payload = {};
    if (error.stack) {
      this.stack = error.stack;
    }
  }
}

type RedirectErrorPayload = {
  code: number;
  data: {
    url: string;
  };
  stack: string;
};

export class RedirectError extends Error {
  payload: RedirectErrorPayload;
  constructor(message: string, payload: RedirectErrorPayload) {
    super(`Fatal error: ${message}`);
    this.name = "RedirectError";
    this.message = message;
    this.payload = payload;
    this.stack = payload.stack;
  }

  toJSON(): object {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
      payload: this.payload,
    };
  }
}

export type ExtendedError =
  | FatalError
  | ApiError
  | PermissionError
  | ValidationError
  | DefaultError;

/**
 * Function checks the error type based on payload similarity
 * PropertyError doesn't included intentionally
 * @param e any error type
 * @returns true if this is extendedError
 */
export function isExtendedError(e: unknown): e is ExtendedError {
  return (
    e instanceof FatalError ||
    e instanceof ApiError ||
    e instanceof PermissionError ||
    e instanceof ValidationError ||
    e instanceof DefaultError ||
    e instanceof AxiosError
  );
}

/**
 * Convert unknown to Error object or keep if it was error
 * @param rawError unknown
 * @returns Error or Extended Error class
 */
export function unknownToError(rawError: unknown): Error | ExtendedError {
  if (isExtendedError(rawError)) return rawError;
  if (rawError instanceof AxiosError) {
    return new DefaultError(rawError);
  }
  if (typeof rawError === "string") return new Error(rawError);

  return new Error("unknown Error with no message, stack and payload");
}

export function handleErrorWithMessages(
  error: unknown,
  messages: Record<string, string | Error> & { defaultMessage?: string | Error }
) {
  // if it's ValidationError try to convert error code to message

  if (error instanceof AxiosError) {
    const customError = error.response?.data as ErrorResponse;

    if (messages[customError.message]) {
      return messages[customError.message];
    }

    if (Array.isArray(customError.message)) {
      if (messages[customError.message[0]]) {
        return messages[customError.message[0]];
      }
      return customError.message[0];
    }

    return customError.message;
  }
}
