import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { couponContext } from "./../../../context/coupon-provider/CouponProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

//translations
import { t } from "@lingui/macro";

export function useCartModalOrder() {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { checkCouponByCode } = useContext(couponContext);

  const [couponCode, setCouponCode] = useState("");

  const [couponDiscountPrice, setCouponDiscountPrice] = useState(0);

  const handleChangeCouponAdminData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { value } = e.target;

      setCouponCode(value);
    },
    [setCouponCode]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (couponCode) {
          const coupon = await checkCouponByCode(couponCode);

          if (coupon && coupon.isActivated) {
            setCouponDiscountPrice(Number(coupon.discount));
          } else {
            error(t(i18n)`The coupon you entered does not exist`);

            setCouponCode("");
            setCouponDiscountPrice(0);
          }
        }
      } catch (e) {
        error(e);
      }
    },
    [couponCode, i18n, setCouponDiscountPrice, checkCouponByCode, error]
  );

  const isDisabledSubmitButton = useMemo(() => !couponCode, [couponCode]);

  useEffect(() => {
    if (!couponCode) {
      setCouponDiscountPrice(0);
    }
  }, [couponCode]);

  return {
    couponCode,
    couponDiscountPrice,
    isDisabledSubmitButton,
    handleChangeCouponAdminData,
    onSubmit,
  };
}
