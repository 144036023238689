import { useContext } from "react";

// translations
import { Trans } from "@lingui/macro";

// context
import { userContext } from "../../../context/user-provider/UserProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";

// hooks
import { useMyCollectionsFetch } from "./use-my-collections";

// components
import Spinner from "../../../components/spinner/Spinner";
import MyCollection from "./my-collection/MyCollection";

// types
import type { CollectionType } from "../../../context/collections-provider/CollectionsProvider.types";
import type {
  OrderedCollection,
  OrderType,
} from "../../../context/order-provider/OrderProvider.types";
import { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./MyCollectionsScreen.module.scss";

export function MyCollectionsScreen() {
  const { userData } = useContext(userContext);
  const { collectionsData } = useContext(collectionsContext);

  const sortedUserData = (orders: OrderType[]) => {
    return orders.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA;
    });
  };

  const getUniqueCollections = (
    orders: OrderType[],
    collections: CollectionType[]
  ) => {
    const allCollections = orders
      .flatMap((order) => order.bookpacks || [])
      .map((orderCollection) => {
        const originalItem = collections.find(
          (item) => item.id === orderCollection.id
        );

        return originalItem
          ? { ...orderCollection, books: originalItem.books }
          : undefined;
      })
      .filter(
        (
          mergedCollection
        ): mergedCollection is OrderedCollection & { books: BookType[] } =>
          mergedCollection !== undefined
      );

    return Array.from(
      new Map(
        allCollections.map((collection) => [collection.id, collection])
      ).values()
    );
  };

  const uniqueCollections = (
    collectionsData: CollectionType[],
    orders: OrderType[]
  ) => getUniqueCollections(sortedUserData(orders), collectionsData);

  const { isMyCollectionsLoading } = useMyCollectionsFetch();

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        <Trans>My Collections</Trans>
      </h3>

      {isMyCollectionsLoading ? (
        <Spinner />
      ) : userData && userData.orders?.length && collectionsData ? (
        <div className={styles.collectionsWrapper}>
          {uniqueCollections(collectionsData, userData.orders).map(
            (collection) => (
              <MyCollection collection={collection} key={collection.id} />
            )
          )}
        </div>
      ) : (
        <h4 className={styles.empty}>
          <Trans>Regrettably, there are currently no collections.</Trans>
        </h4>
      )}
    </section>
  );
}
