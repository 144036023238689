import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";

// context
export function useBookFetch() {
  const { error } = useContext(errorContext);
  const { getBookById } = useContext(booksContext);

  const { bookId } = useParams();

  const [isBookLoading, setIsBookLoading] = useState(false);

  const booksAdminFetch = async () => {
    try {
      if (bookId) {
        setIsBookLoading(true);

        await getBookById(bookId);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsBookLoading(false);
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId]);

  return {
    isBookLoading,
  };
}
