import { useContext } from "react";

// translation
import { Trans } from "@lingui/macro";

// contexts
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

// hooks
import { useBankSelectingForm } from "./use-bank-selecting";

// icons
import { LockIcon } from "../../../../assets/icons/LockIcon";
import { CloseIcon } from "../../../../assets/icons/CloseIcon";

// components
import { BankItem } from "./bank-item/BankItem";
import { BankSelector } from "../../../../components/bank-selector/BankSelector";

// types
import { BankType } from "../../../../context/open-banking-provider/OpenBankingProvider.types";

// styles
import styles from "./BankSelecting.module.scss";

type BankSelectingProps = {
  totalPrice: string;
  setSelectedBank: React.Dispatch<
    React.SetStateAction<(BankType & { country: string }) | null>
  >;
  setIsOpenClosingModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export function BankSelecting({
  totalPrice,
  setIsOpenClosingModal,
  setSelectedBank,
}: BankSelectingProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const {
    countryFormData,
    countriesOptions,
    banksForSelectedCountry,
    handleChangeCountryData,
  } = useBankSelectingForm();

  return (
    <form className={styles.form}>
      <div className={styles.formHeader}>
        <CloseIcon
          className={styles.closeIcon}
          width={26}
          height={26}
          color="#FDFDFD"
          onClick={() => setIsOpenClosingModal(true)}
        />
        <div className={styles.formInfo}>
          <p className={styles.title}>
            <Trans>Choose your bank</Trans>
          </p>
          <div className={styles.amountWrapper}>
            <p className={styles.amountTitle}>
              <Trans>Amount</Trans>
            </p>
            <p className={styles.amount}>
              {getCurrencySymbol()} {totalPrice}
            </p>
          </div>
        </div>
        <div className={styles.selectorWrapper}>
          <div className={styles.safeWrapper}>
            <LockIcon />
            <p className={styles.safeText}>
              <Trans>Safe authorisation</Trans>
            </p>
          </div>

          <BankSelector
            className={styles.selector}
            options={countriesOptions}
            value={countriesOptions.find(
              ({ value }) => value === countryFormData
            )}
            onChange={handleChangeCountryData}
          />
        </div>
      </div>

      <div className={styles.mainContent}>
        {banksForSelectedCountry.length ? (
          <div className={styles.banksWrapper}>
            {banksForSelectedCountry.map((bank) => (
              <BankItem
                bank={{ ...bank, country: countryFormData }}
                setSelectedBank={setSelectedBank}
                key={bank.code}
              />
            ))}
          </div>
        ) : (
          <div className={styles.emptyContainer}>
            <h3 className={styles.emptyTitle}>
              <Trans>
                Unfortunately, there are no suitable banks for the selected
                country.
              </Trans>
            </h3>
          </div>
        )}
      </div>
    </form>
  );
}
