import { TransactionFormType } from "../../../context/open-banking-provider/OpenBankingProvider.types";

export const INIT_TRANSACTION_FORM_DATA: TransactionFormType = {
  amount: 0,
  browser: {
    acceptHeader: "",
    colorDepth: 0,
    javaEnabled: true,
    language: "",
    screenHeight: 0,
    screenWidth: 0,
    timeZoneOffset: 0,
    userAgent: "",
    windowHeight: 0,
    windowWidth: 0,
  },
  client: {
    email: "",
    phone: "",
  },
  currencyCode: "",
  locale: "",
  orderId: "",
  payerData: {
    bankId: "",
    payerIban: "",
    paymentCountry: "",
    fullName: "",
  },
};
