import React, { FunctionComponent, useContext, useMemo } from "react";

// translations
import { t } from "@lingui/macro";

// contexts
import { localesContext } from "../local-provider/LocalProvider";
import { apiContext } from "../api-provider/ApiProvider";
import { currencyContext } from "../currency-provider/CurrencyProvider";

// helpers
import { handleErrorWithMessages } from "../error-provider/error";

// consts
import {
  API_URL_PAYMENT_CHECKOUT,
  API_URL_PAYMENT_CHECKOUT_CONFIRM,
} from "./PaymentProvider.consts";

// schemas
import { paymentSchema, refillSchema } from "./PaymentProvider.schemas";

// types
import type {
  PaymentContext,
  PaymentFormType,
  PaymentProviderProps,
} from "./PaymentProvider.types";

export const paymentContext = React.createContext({} as PaymentContext);

export const PaymentProvider: FunctionComponent<PaymentProviderProps> = (
  props
) => {
  const { i18n } = useContext(localesContext);
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);

  const { children } = props;

  const checkoutPayment = async (orderId: string, total: string) => {
    try {
      const currentCurrency = getCurrency();

      const payment = await api(
        API_URL_PAYMENT_CHECKOUT,
        { method: "POST", data: { orderId, currency: currentCurrency, total } },
        refillSchema
      );

      if (payment) {
        window.location.href = payment.url;
      }
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "Unable to create UAH transaction": t(
          i18n
        )`Unable to create UAH transaction`,
        "Unable to create USD transaction": t(
          i18n
        )`Unable to create USD transaction`,
        "Unable to create GBP transaction": t(
          i18n
        )`Unable to create GBP transaction`,
      });
    }
  };

  const checkoutPaymentConfirm = async (formData: PaymentFormType) => {
    try {
      await api(
        API_URL_PAYMENT_CHECKOUT_CONFIRM,
        { method: "POST", data: formData },
        paymentSchema
      );
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      checkoutPayment,
      checkoutPaymentConfirm,
    }),
    [checkoutPayment, checkoutPaymentConfirm]
  );

  return (
    <paymentContext.Provider value={contextValue}>
      {children}
    </paymentContext.Provider>
  );
};
