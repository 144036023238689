import React from "react";
import { useNavigate } from "react-router-dom";

// icons
import { FailedIcon } from "../../../assets/icons/FailedIcon";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PaymentFailed.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function PaymentFailed() {
  const navigate = useNavigate();

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <FailedIcon />
        <p className={styles.title}>
          <Trans>Payment was declined</Trans>
        </p>
        <p className={styles.description}>
          <Trans>Please try again later or contact our support service</Trans>
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => navigate(PATHS.profile)}>
          <Trans>My profile</Trans>
        </Button>
      </div>
    </section>
  );
}
