import { useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// contexts
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { openBankingContext } from "../../../context/open-banking-provider/OpenBankingProvider";

// translation
import { Trans } from "@lingui/macro";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import { RoundDiagram } from "./round-diagram/RoundDiagram";

// styles
import styles from "./TransactionLoading.screen.module.scss";

export function TransactionLoadingScreen() {
  const { error } = useContext(errorContext);
  const { checkTheTransaction } = useContext(openBankingContext);

  const { transactionId } = useParams();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const consent = searchParams.get("consent");

  const checkTheTransactionFetch = async (
    transactionId: string,
    consent: string
  ) => {
    try {
      await checkTheTransaction(transactionId, consent);
    } catch (e) {
      error(e);
    }
  };

  useEffect(() => {
    if (!consent) {
      return navigate(PATHS.failed);
    }
    if (transactionId) {
      checkTheTransactionFetch(transactionId, consent);
    }
  }, [transactionId, consent]);

  return (
    <section className={styles.transactionSection}>
      <div className={styles.contentWrapper}>
        <RoundDiagram blockSize={98} lineWidth={10} progress={85} />
        <p className={styles.text}>
          <Trans>Payment in processing</Trans>
        </p>
      </div>
    </section>
  );
}
