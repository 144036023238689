import { z } from "zod";

// schemas
import { bookSchema } from "./../books-provider/BooksProvider.schemas";
import { collectionSchema } from "../collections-provider/CollectionsProvider.schemas";

export const orderedCollectionSchema = collectionSchema.omit({ books: true });

export const orderSchema = z.object({
  id: z.string(),
  status: z.enum(["Completed", "Declined", "Error", "Refunded", "Pending"]),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  books: z.array(bookSchema),
  bookpacks: z.array(orderedCollectionSchema),
  total: z.string(),
});

export const allOrdersSchema = z.object({
  total: z.number(),
  data: z.array(orderSchema),
});

export const orderStatusSchema = z.object({
  id: z.string(),
  status: z.string(),
});
