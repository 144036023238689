import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// components
import { Book } from "./book/Book";
import { Recomendations } from "../recomendations/Recomendations";
import Button from "../../../../components/button/Button";
import { Collection } from "./collection/Collection";

// consts
import { PATHS } from "../../../../route/route.controls";

// icons
import { ArrowRightIcon } from "../../../../assets/icons/ArrowRightIcon";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";
import type { CollectionType } from "../../../../context/collections-provider/CollectionsProvider.types";

// styles
import styles from "./RatedBooks.module.scss";

//translations
import { Trans } from "@lingui/macro";

type RatedBooksProps = {
  title: string;
  books?: BookType[] | null;
  collections?: CollectionType[] | null;
  type?: "mainBooks" | "mainCollections" | "books" | "collections";
};

export function RatedBooks({
  title,
  books,
  collections,
  type,
}: RatedBooksProps) {
  const navigate = useNavigate();

  const isBooksPageType = type === "books";
  const isCollectionsPageType = type === "collections";
  const isMainBooksPageType = type === "mainBooks";
  const isMainCollectionsPageType = type === "mainCollections";

  const currentBooks = (books: BookType[]) => {
    return isMainBooksPageType ? books.slice(0, 5) : books;
  };

  const currentCollections = (collections: CollectionType[]) => {
    return isMainCollectionsPageType ? collections.slice(0, 5) : collections;
  };

  const handleReadMore = useCallback(() => {
    if (isMainBooksPageType) {
      const generatedLink = generatePath(PATHS.bookWithGenre, {
        genre: "business",
      });

      return navigate(generatedLink);
    } else {
      return navigate(PATHS.collections);
    }
  }, [isMainBooksPageType, PATHS, generatePath, navigate]);

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <h3
          className={
            isBooksPageType || isCollectionsPageType
              ? styles.title
              : styles.mainTitle
          }
        >
          {title}
        </h3>
        <div className={styles.mainBooksWrapper}>
          {(isMainCollectionsPageType || isCollectionsPageType) &&
          collections &&
          collections.length
            ? currentCollections(collections).map((collection) => (
                <Collection collection={collection} key={collection.id} />
              ))
            : null}

          {(isMainBooksPageType || isBooksPageType) && books && books.length
            ? currentBooks(books).map((book) => (
                <Book book={book} key={book.id} />
              ))
            : null}
        </div>
        {isBooksPageType && books && books.length ? (
          <div className={styles.recomendationsWrapper}>
            <Recomendations type="genre" />
          </div>
        ) : null}

        {isCollectionsPageType && collections && collections.length ? (
          <div className={styles.recomendationsWrapper}>
            <Recomendations type="genre" />
          </div>
        ) : null}

        {isMainBooksPageType || isMainCollectionsPageType ? (
          <div className={styles.buttonWrapper}>
            <Button
              width="auto"
              icon={<ArrowRightIcon color="#fff" />}
              onClick={handleReadMore}
            >
              <Trans>Read more</Trans>
            </Button>
          </div>
        ) : null}
      </div>
    </section>
  );
}
