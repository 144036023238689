import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// contexts
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { openBankingContext } from "../../../context/open-banking-provider/OpenBankingProvider";
import { userContext } from "../../../context/user-provider/UserProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// hooks
import { usePreviewModalFetch } from "../../../components/preview-cart-modal/use-preview-modal";

// consts
import { INIT_TRANSACTION_FORM_DATA } from "./OpenBanking.screen.consts";

// types
import type {
  BankType,
  TransactionFormType,
} from "../../../context/open-banking-provider/OpenBankingProvider.types";
import { t } from "@lingui/macro";

export function useOpenBankingForm() {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { getCurrency } = useContext(currencyContext);
  const { locale } = useContext(localesContext);
  const { userData } = useContext(userContext);
  const { createTransaction } = useContext(openBankingContext);

  const { orderId } = useParams();

  const { isCartLoading, totalPrice } = usePreviewModalFetch(true);

  const [selectedBank, setSelectedBank] = useState<
    (BankType & { country: string }) | null
  >(null);

  const [confirmPage, setConfirmPage] = useState<boolean>(false);

  const [payerFormData, setPayerFormData] = useState<TransactionFormType>(
    INIT_TRANSACTION_FORM_DATA
  );

  useEffect(() => {
    setSelectedBank(null);
    setPayerFormData(INIT_TRANSACTION_FORM_DATA);
    setConfirmPage(false);
  }, [orderId]);

  const handleChangePayerData = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const { name, value } = e.target;

        setPayerFormData((prev) => ({
          ...prev,
          payerData: { ...prev.payerData, [name]: value },
        }));
      } catch (e) {
        error(e);
      }
    },
    [setPayerFormData, error]
  );

  const clearPayerData = () => {
    setPayerFormData(INIT_TRANSACTION_FORM_DATA);
  };

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        e.preventDefault();

        if (selectedBank && userData && orderId) {
          const browser = {
            acceptHeader: navigator.userAgent,
            colorDepth: window.screen.colorDepth,
            javaEnabled: navigator.javaEnabled(),
            language: navigator.language,
            screenHeight: window.screen.height,
            screenWidth: window.screen.width,
            timeZoneOffset: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
          };

          const client = {
            email: userData.email || "",
            phone: userData.phone,
          };

          const payerData = {
            bankId: selectedBank.code,
            ...(selectedBank.country !== "GB" && {
              payerIban: payerFormData.payerData.payerIban,
            }),
            ...(selectedBank.country !== "GB" && {
              paymentCountry: selectedBank.country,
            }),
            ...(selectedBank.country === "GB" && {
              payerAccountNumber:
                payerFormData.payerData.payerAccountNumber || "",
            }),
            ...(selectedBank.country === "GB" && {
              payerSortCode: payerFormData.payerData.payerSortCode || "",
            }),
            fullName: payerFormData.payerData.fullName,
          };

          await createTransaction({
            amount: Number(totalPrice) || 0,
            browser,
            client,
            currencyCode: getCurrency(),
            locale,
            orderId,
            payerData,
          });
        }
      } catch (e) {
        error(e);
      }
    },
    [locale, userData, selectedBank, payerFormData, orderId, error, success]
  );

  const validateFullName = useCallback(() => {
    const passwordRegex = /^[a-zA-Z\s]+$/;

    const isValid = passwordRegex.test(payerFormData.payerData.fullName);

    if (isValid || !payerFormData.payerData.fullName) {
      return {
        error: false,
        message: "",
      };
    } else {
      return {
        error: true,
        message: t(i18n)`Full name should contain only Latin characters`,
      };
    }
  }, [payerFormData, i18n.locale]);

  const validateIBAN = useCallback(() => {
    const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
    const isValidIBAN = ibanRegex.test(payerFormData.payerData.payerIban || "");

    if (isValidIBAN || !payerFormData.payerData.payerIban) {
      return {
        error: false,
        message: "",
      };
    } else {
      return {
        error: true,
        message: t(i18n)`Please enter a valid IBAN format`,
      };
    }
  }, [payerFormData, i18n.locale]);

  const isDisabledButton = useMemo(
    () =>
      selectedBank?.country === "GB"
        ? !payerFormData.payerData.payerSortCode ||
          !payerFormData.payerData.payerAccountNumber ||
          !payerFormData.payerData.fullName ||
          validateFullName().error
        : !payerFormData.payerData.payerIban ||
          !payerFormData.payerData.fullName ||
          validateFullName().error ||
          validateIBAN().error,
    [payerFormData, validateFullName, validateIBAN]
  );

  return {
    selectedBank,
    confirmPage,
    payerFormData,
    isDisabledButton,
    isCartLoading,
    totalPrice,
    setSelectedBank,
    setConfirmPage,
    handleChangePayerData,
    validateFullName,
    validateIBAN,
    clearPayerData,
    onSubmit,
  };
}

export function useOpenBankingFetch() {
  const { error } = useContext(errorContext);
  const { getCountries, getBanks } = useContext(openBankingContext);

  const [isOpenBankingLoading, setIsOpenBankingLoading] = useState(true);

  const banksFetch = async () => {
    try {
      await Promise.all([getCountries(), getBanks()]);
    } catch (err) {
      error(err);
    } finally {
      setIsOpenBankingLoading(false);
    }
  };

  useEffect(() => {
    banksFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isOpenBankingLoading };
}
