// images
import visa_image from "../../assets/images/visa_image.png";

// icons
import { LogoIcon } from "../../assets/icons/LogoIcon";

// consts
import { useAsideConsts } from "./use-footer.consts";

// styles
import styles from "./Footer.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function Footer() {
  const { footerData } = useAsideConsts();
  return (
    <footer className={styles.footer}>
      <div className={styles.contentWrapper}>
        <div className={styles.navigationWrapper}>
          <div className={styles.footerInfo}>
            <LogoIcon color="#fff" />
            <div>
              <p>
                <Trans>Don't miss our updates!</Trans>
              </p>
              <p>
                <Trans>Register to follow the latest news</Trans>
              </p>
            </div>
            <img
              src={visa_image}
              alt="visa cards"
              className={styles.visaImage}
            />
          </div>
          <div className={styles.footerItemWrapper}>
            {footerData.map((footerItem) => (
              <div key={footerItem.title} className={styles.titleWrapper}>
                <p className={styles.title}>{footerItem.title}</p>

                <div className={styles.footerItems}>
                  {footerItem.items.map((item, index) => (
                    <p className={styles.footerItem} key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footerTitle}>
          <p>Thrivestore.co.uk</p>
        </div>
      </div>
    </footer>
  );
}
