import { useContext } from "react";

// translations
import { Trans } from "@lingui/macro";

// hooks
import { useHistoryUserConsts } from "./use-history-user.consts";
import { useHistoryUserFetch } from "./use-history-admin";

// contexts
import { localesContext } from "../../../context/local-provider/LocalProvider";
import { userContext } from "../../../context/user-provider/UserProvider";

// components
import Spinner from "../../../components/spinner/Spinner";

// helpers
import { formattedDate } from "../../../helpers/format-date";

// types
import type { OrderType } from "../../../context/order-provider/OrderProvider.types";

// styles
import styles from "./HistoryUserScreen.module.scss";

export function HistoryAdminScreen() {
  const { locale } = useContext(localesContext);
  const { userData } = useContext(userContext);

  const { statusData } = useHistoryUserConsts();

  const { isHistoryLoading } = useHistoryUserFetch();

  const sortedUserData = (orders: OrderType[]) => {
    return orders.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA;
    });
  };

  return (
    <section className={styles.container}>
      {isHistoryLoading ? (
        <Spinner />
      ) : userData && userData.orders?.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>
              <Trans>Order</Trans>
            </div>
            <div>
              <Trans>Books</Trans>
            </div>
            <div>
              <Trans>Collections</Trans>
            </div>
            <div>
              <Trans>Purchase date</Trans>
            </div>
            <div>
              <Trans>Status</Trans>
            </div>
            <div>
              <Trans>Price</Trans>
            </div>
          </div>

          {sortedUserData(userData.orders).map((order) => (
            <div className={styles.tableRowBody} key={order.id}>
              <div>{order.id}</div>
              <div className={styles.books}>
                {order.books.map((book, index) => (
                  <div>
                    <p>
                      {index + 1}. {book.title}
                    </p>
                  </div>
                ))}
              </div>
              <div className={styles.books}>
                {order.bookpacks.map((collection, index) => (
                  <div>
                    <p>
                      {index + 1}. {collection.title}
                    </p>
                  </div>
                ))}
              </div>
              <div>{formattedDate(order.updatedAt, locale)}</div>
              <div>{statusData[order.status]}</div>
              <div>{order.total}</div>
            </div>
          ))}
        </div>
      ) : (
        <h4 className={styles.title}>
          <Trans>Regrettably, there are currently no history.</Trans>
        </h4>
      )}
    </section>
  );
}
