import { SVGProps } from "react";

const NumberIcon = ({
  className,
  color = "#FDFDFD",
  width = 15,
  height = 12,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.055 11.801V.38h2.578l3.953 8.125h.14c-.025-.26-.054-.573-.085-.937-.026-.37-.05-.75-.07-1.14-.022-.391-.032-.748-.032-1.071V.379h2.078v11.422H6.055L2.07 3.621h-.11c.027.292.053.639.08 1.04.03.395.056.791.077 1.187.021.396.031.742.031 1.04V11.8H.055Zm9.648 0v-1.469h4.992v1.47H9.703Zm2.485-2.14c-.51 0-.967-.112-1.368-.336a2.39 2.39 0 0 1-.937-.977c-.224-.427-.336-.94-.336-1.539 0-.901.24-1.596.719-2.086.479-.495 1.13-.742 1.953-.742.5 0 .948.112 1.344.336.4.219.716.539.945.96.234.417.351.928.351 1.532 0 .901-.242 1.602-.726 2.102-.485.5-1.133.75-1.945.75Zm.015-1.282c.37 0 .638-.13.805-.39.167-.266.25-.66.25-1.18 0-.52-.083-.909-.25-1.164-.167-.26-.435-.39-.805-.39-.38 0-.656.13-.828.39-.167.255-.25.643-.25 1.164 0 .52.083.914.25 1.18.172.26.448.39.828.39Z"
        fill={color}
        fill-opacity=".5"
      />
    </svg>
  );
};
export { NumberIcon };
