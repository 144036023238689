import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// consts
import { PATHS } from "../../route/route.controls";

// components
import { Header } from "../../components/header/Header";
import { Aside } from "../../components/aside/Aside";
import { Footer } from "../../components/footer/Footer";

// context
import { sessionContext } from "../../context/session-provider/SessionProvider";

// styles
import styles from "./UserLayout.module.scss";

export function UserLayout(): JSX.Element {
  const { getRoleFromToken } = useContext(sessionContext);

  const { pathname } = useLocation();

  const role = getRoleFromToken();

  return (
    <div className={styles.container}>
      <Header isAdminRoutes />
      <div className={styles.mainWrapper}>
        <Aside role={role} />

        <div className={styles.outletWrapper}>
          {pathname === PATHS.myBooks || pathname === PATHS.myCollections ? (
            <>
              <div className={styles.violetCircle} />
              <div className={styles.redCircle} />
            </>
          ) : null}

          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
