import { generatePath, useNavigate } from "react-router-dom";

// translations
import { Trans } from "@lingui/macro";

// consts
import { PATHS } from "../../../../../route/route.controls";

// icons
import { ArrowRightIcon } from "../../../../../assets/icons/ArrowRightIcon";

// components
import Button from "../../../../../components/button/Button";

// types
import type { IntroductionItemType } from "../Introduction.types";

// styles
import styles from "./CarouselItem.module.scss";

type CarouselItemProps = {
  introductionItem: IntroductionItemType;
};

export default function CarouselItem({ introductionItem }: CarouselItemProps) {
  const navigate = useNavigate();

  const generatedLink = generatePath(PATHS.bookWithGenre, {
    genre: "business",
  });

  return (
    <div className={styles.container}>
      <div className={styles.itemInfo}>
        <div className={styles.title}>{introductionItem.title}</div>
        <p>{introductionItem.description}</p>
        <div>
          <Button
            width="auto"
            icon={<ArrowRightIcon color="#fff" />}
            onClick={() => navigate(generatedLink)}
          >
            <Trans>Explore Now</Trans>
          </Button>
        </div>
      </div>
      <div className={styles.imageWrapper}>{introductionItem.img}</div>
    </div>
  );
}
