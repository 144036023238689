import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";

// components
import Button from "../button/Button";
import { BookAdminModal } from "../../side/admin/books-admin-screen/BookAdminModal/BookAdminModal";
import { CouponAdminModal } from "../../side/admin/coupons-admin-screen/CouponAdminModal/CouponAdminModal";

// context
import { localesContext } from "../../context/local-provider/LocalProvider";

// consts
import { PATHS } from "../../route/route.controls";

// types
import type { TabType } from "./Tabs.types";

// styles
import styles from "./Tabs.module.scss";
import { CollectionAdminModal } from "../../side/admin/collections-admin-screen/CollectionAdminModal/CollectionAdminModal";

//translations
import { t } from "@lingui/macro";

type TabsProps = {
  tabs: TabType[];
};

export function Tabs({ tabs }: TabsProps) {
  const { i18n } = useContext(localesContext);
  const { pathname: currentURL } = useLocation();

  const [isOpenBookModal, setIsOpenBookModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenCollectionModal, setIsOpenCollectionModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState<
    "create" | "edit" | null
  >(null);

  const isActiveLink = (link: string) => link === currentURL;

  const handleOpenBookModal = useCallback(() => {
    setIsOpenBookModal("create");
  }, [setIsOpenBookModal]);

  const handleOpenCollectionModal = useCallback(() => {
    setIsOpenCollectionModal("create");
  }, [setIsOpenCollectionModal]);

  const handleOpenCouponModal = useCallback(() => {
    setIsOpenCouponModal("create");
  }, [setIsOpenCouponModal]);

  const handleOpenModal = useCallback(() => {
    if (currentURL === PATHS.adminBooks) {
      return handleOpenBookModal();
    } else if (currentURL === PATHS.adminCollections) {
      return handleOpenCollectionModal();
    }

    return handleOpenCouponModal();
  }, [
    currentURL,
    PATHS,
    handleOpenBookModal,
    handleOpenCollectionModal,
    handleOpenCouponModal,
  ]);

  const buttonTitle = () => {
    if (currentURL === PATHS.adminBooks) {
      return t(i18n)`Add Product`;
    } else if (currentURL === PATHS.adminCollections) {
      return t(i18n)`Add Collection`;
    }

    return t(i18n)`Add Coupon`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.linksWrapper}>
        {tabs.map((tab) => (
          <Link
            key={tab.to}
            to={tab.to}
            className={classnames(styles.link, {
              [styles.activeLink]: isActiveLink(tab.to),
            })}>
            {tab.title}
          </Link>
        ))}
      </div>
      <div className={styles.controlWrapper}>
        <div>
          <Button className={styles.button} onClick={handleOpenModal}>
            {buttonTitle()}
          </Button>
        </div>
      </div>

      <BookAdminModal
        isOpen={isOpenBookModal}
        onClose={() => setIsOpenBookModal(null)}
        book={null}
      />

      <CollectionAdminModal
        isOpen={isOpenCollectionModal}
        onClose={() => setIsOpenCollectionModal(null)}
        collection={null}
      />

      <CouponAdminModal
        isOpen={isOpenCouponModal}
        onClose={() => setIsOpenCouponModal(null)}
        coupon={null}
      />
    </div>
  );
}
