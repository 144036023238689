import { format, Locale } from "date-fns";
import { enUS, fr, de, uk } from "date-fns/locale";

/**
 * Function for formatting a date in the desired format.
 * @param {string} date - A string representing the date in the format "2024-02-01T13:39:15.672Z".
 * @returns {string} - Formatted date as a string.
 */

const localeMap: Record<string, Locale> = {
  en: enUS,
  fr: fr,
  de: de,
  uk: uk,
};

export const formattedDate = (
  date: string | undefined,
  locale: string,
): string => {
  const language = locale || "en";
  const currentLocale = localeMap[language] || enUS;
  const dateFormat =
    language === "en"
      ? "EEEE, d MMMM yyyy, h:mm a"
      : "EEEE, d MMMM yyyy, HH:mm";
  if (date) {
    const inputDate = new Date(date);
    return format(inputDate, dateFormat, {
      locale: currentLocale,
    });
  }

  return "";
};
