import { SVGProps } from "react";

const LockIcon = ({
  className,
  color = "#34A853",
  width = 14,
  height = 15,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.503 5.166h-.584V4a2.918 2.918 0 0 0-5.833 0v1.166h-.583a1.17 1.17 0 0 0-1.167 1.167v5.833a1.17 1.17 0 0 0 1.167 1.167h7a1.17 1.17 0 0 0 1.166-1.167V6.333a1.17 1.17 0 0 0-1.166-1.167Zm-3.5 5.25A1.17 1.17 0 0 1 5.836 9.25a1.17 1.17 0 0 1 1.167-1.167A1.17 1.17 0 0 1 8.169 9.25a1.17 1.17 0 0 1-1.166 1.166Zm-1.75-5.25V4c0-.969.781-1.75 1.75-1.75.968 0 1.75.781 1.75 1.75v1.166h-3.5Z"
        fill={color}
        opacity=".8"
      />
    </svg>
  );
};
export { LockIcon };
