import { ReactNode } from "react";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";

// styles
import styles from "./ConfirmModal.module.scss";

//translations
import { Trans } from "@lingui/macro";

type ConfirmModalProps = {
  isOpen: boolean;
  modalTitle: string;
  modalText: ReactNode;
  applyButtonText?: string;
  declinedButtonText?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export function ConfirmModal({
  isOpen,
  modalTitle,
  modalText,
  applyButtonText,
  declinedButtonText,
  onClose,
  onSubmit,
}: ConfirmModalProps) {
  const { width } = useWindowSize();

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={modalTitle}
      width={width > 767 ? "40%" : "97%"}
    >
      <form className={styles.form}>
        <div className={styles.textWrapper}>
          <p>{modalText}</p>
        </div>
        <div className={styles.buttonswWrapper}>
          <Button
            onClick={onClose}
            variant="outline"
            className={styles.cancelButton}
          >
            {declinedButtonText ? declinedButtonText : <Trans>Cancel</Trans>}
          </Button>
          <Button onClick={onSubmit} type="submit">
            {applyButtonText ? applyButtonText : <Trans>Delete</Trans>}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
