import { useContext, useEffect, useState } from "react";

// contexts
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { userContext } from "../../../context/user-provider/UserProvider";

export function useMyBooksFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);
  const { getOrders } = useContext(orderContext);

  const [isMyBooksLoading, setIsMyBooksLoading] = useState(false);

  const getMyBooksData = async () => {
    try {
      setIsMyBooksLoading(true);

      await Promise.all([getProfile(), getOrders()]);
    } catch (err) {
      error(err);
    } finally {
      setIsMyBooksLoading(false);
    }
  };

  useEffect(() => {
    getMyBooksData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isMyBooksLoading };
}
