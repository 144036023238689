import { useContext } from "react";

// translations
import { t } from "@lingui/macro";

// compoents
import { CircleWrapper } from "./circle-wrapper/CircleWrapper";
import { Introduction } from "./introduction/Introduction";
import { RatedBooks } from "./rated-books/RatedBooks";
import { Recomendations } from "./recomendations/Recomendations";
import { Discount } from "./discount/Discount";
import { Feedback } from "./feedback/Feedback";

// hooks
import { useMainScreenFetch } from "./use-main-screen";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { collectionsContext } from "../../../context/collections-provider/CollectionsProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// styles
import styles from "./Main.screen.module.scss";

export function MainScreen() {
  const { i18n } = useContext(localesContext);
  const { ratedBooks } = useContext(booksContext);
  const { ratedCollections } = useContext(collectionsContext);

  const { isMainScreenLoading } = useMainScreenFetch();

  return (
    <div className={styles.container}>
      <Introduction />

      <CircleWrapper>
        {/* Annual rating */}
        {isMainScreenLoading ? null : (
          <>
            {ratedCollections && ratedCollections.length ? (
              <RatedBooks
                title={t(i18n)`Best collections`}
                collections={ratedCollections}
                type="mainCollections"
              />
            ) : null}

            {ratedBooks && ratedBooks.length ? (
              <>
                <RatedBooks
                  title={t(i18n)`Top rated books of the 2024`}
                  books={ratedBooks}
                  type="mainBooks"
                />

                <Recomendations />

                {/* Monthly rating */}
                <RatedBooks
                  title={t(i18n)`Top rated books of last month`}
                  books={ratedBooks}
                  type="mainBooks"
                />
              </>
            ) : null}
          </>
        )}
        <Discount />

        <Feedback />
      </CircleWrapper>
    </div>
  );
}
