export const autoDownload = (
  fileData: BlobPart,
  filename: string,
  type: string
) => {
  const blob = new Blob([fileData], { type });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${filename}.pdf`;
  document.body.appendChild(link);

  link.click();

  window.URL.revokeObjectURL(url);
  link.remove();
};
