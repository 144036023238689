// styles
import styles from "./RoundDiagram.module.scss";

export type RoundDiagramType = {
  blockSize?: number;
  children?: JSX.Element;
  lineWidth?: number;
  progress: number;
};

/**
 * Component for displaying a circular diagram.
 * @param {JSX.Element} [props.children] - Optional children.
 * @param {number} props.lineWidth - Width of the diagram's line.
 * @param {number} props.progress - Progress in percent of the diagram (from 0 to 100).
 * @param {number} props.size - Diameter of the diagram.
 * @param {Object} props - Properties for the RoundDiagram component.
 * @returns {JSX.Element} RoundDiagram component.
 */
export function RoundDiagram(props: RoundDiagramType): JSX.Element {
  const { blockSize = 74, children, lineWidth = 3, progress } = props;

  const outerRadius = blockSize / 2;
  const innerRadius = outerRadius - lineWidth;
  const circumference = 2 * Math.PI * innerRadius;
  const circumferenceProgress = ((100 - progress) * circumference) / 100;

  const containerStyles = {
    width: `${blockSize}px`,
    height: `${blockSize}px`,
  };

  return (
    <div style={containerStyles} className={styles.diagramBlock}>
      {progress !== 0 && (
        <svg width={blockSize} height={blockSize} className={styles.svgDiagram}>
          <g transform={`rotate(-90 ${outerRadius} ${outerRadius})`}>
            <circle
              className={styles.progressCircle}
              cx={outerRadius}
              cy={outerRadius}
              fill="transparent"
              strokeLinecap="round"
              strokeWidth={lineWidth}
              r={innerRadius}
            />

            <circle
              className={styles.restCircle}
              cx={outerRadius}
              cy={outerRadius}
              fill="transparent"
              r={innerRadius}
              strokeDasharray={circumference}
              strokeDashoffset={circumferenceProgress}
              strokeLinecap="round"
              strokeWidth={lineWidth}
            />
          </g>
        </svg>
      )}
      {children}
    </div>
  );
}
