import { useCallback, useContext, useState } from "react";

// translations
import { t } from "@lingui/macro";

// contexts
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";

// helpers
import { getImageUrl } from "../../../../helpers/getImageUrl";
import { truncateString } from "../../../../helpers/truncate-string";
import { autoDownload } from "../../../../helpers/autoDownload";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// icons
import { DownloadIcon } from "../../../../assets/icons/DownloadIcon";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./MyBook.module.scss";

type MyBookProps = {
  book: BookType;
};

export default function MyBook({ book }: MyBookProps) {
  const { error, success } = useContext(errorContext);
  const { i18n } = useContext(localesContext);
  const { downloadPDF } = useContext(storageContext);

  const { width } = useWindowSize();

  const [amountOfDescriptionWords, setAmountOfDescriptionWords] = useState(
    width > 767 ? 150 : 110
  );

  const handleDownload = useCallback(
    async (key: string | null | undefined, title: string) => {
      if (!key) {
        error(t(i18n)`Unable to download the book at this time.`);
        return;
      }

      try {
        const file = await downloadPDF(key);

        if (file) {
          autoDownload(file, title, "application/pdf");
        }

        success(t(i18n)`Book was successfully downloaded`);
      } catch (e) {
        error(e);
      }
    },
    [downloadPDF]
  );

  return (
    <div key={book.id} className={styles.book}>
      <div className={styles.imageWrapper}>
        <img
          src={getImageUrl(book?.picture)}
          alt={book.title}
          loading="lazy"
          className={styles.image}
        />
      </div>

      <div className={styles.title}>{book.title}</div>

      <div className={styles.description}>
        {truncateString(book.description, amountOfDescriptionWords)}
      </div>

      {book.description.length > amountOfDescriptionWords ? (
        <p
          className={styles.readMore}
          onClick={() => setAmountOfDescriptionWords(book.description.length)}
        >
          Read more
        </p>
      ) : null}

      <div className={styles.downloadWrapper}>
        {book.source ? (
          <DownloadIcon
            onClick={() => handleDownload(book.source?.key, book.title)}
            className={styles.downloadIcon}
            height={22}
            width={22}
          />
        ) : null}
      </div>
    </div>
  );
}
