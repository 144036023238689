import { useCallback, useContext } from "react";

// components
import Button from "../../../../components/button/Button";

// contexts
import { cartContext } from "../../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

// types
import type { BookType } from "../../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./AddToCart.module.scss";
import { Trans } from "@lingui/macro";

//translations

type AddToCartProps = {
  book: BookType;
};

export function AddToCart({ book }: AddToCartProps) {
  const { getCurrencySymbol } = useContext(currencyContext);
  const { addBookToCart } = useContext(cartContext);

  const handleAddBookToCart = useCallback(
    (selectedBook: BookType) => {
      addBookToCart(selectedBook);
    },
    [addBookToCart]
  );

  return (
    <div className={styles.container}>
      <div className={styles.priceWrapper}>
        <div className={styles.newPriceWrapper}>
          <p>
            <Trans>Price</Trans>
          </p>
          <p className={styles.newPrice}>
            {getCurrencySymbol()}
            {book.discountPrice ? book.discountPrice : book.price}
          </p>
        </div>

        {book.discountPrice ? (
          <div className={styles.oldPriceWrapper}>
            <p>
              <Trans>Last price</Trans>
            </p>
            <div className={styles.diagonalLine}>
              <p className={styles.oldPrice}>
                {getCurrencySymbol()}
                {book.price}
              </p>
            </div>
          </div>
        ) : null}
      </div>

      <Button onClick={() => handleAddBookToCart(book)}>
        <Trans>Add to cart</Trans>
      </Button>
    </div>
  );
}
