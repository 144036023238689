import { useCallback, useContext, useState } from "react";

// translations
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

// context
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// components
import Spinner from "../../../components/spinner/Spinner";
import { ItemsCounter } from "../../../components/items-counter/ItemsCounter";
import { BookAdminModal } from "./BookAdminModal/BookAdminModal";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";
import { Pagination } from "../../../components/pagination/Pagination";

// icons
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";

// hooks
import { useBookAdminForm, useBooksAdminFetch } from "./use-books-admin";

// helpers
import { getImageUrl } from "../../../helpers/getImageUrl";

// type
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./BooksAdminScreen.module.scss";

export function BooksAdminScreen() {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { books, total } = useContext(booksContext);

  const {
    isBooksAdminLoading,
    itemsAmount,
    currentPage,
    setItemsAmount,
    setItemOffset,
    setCurrentPage,
  } = useBooksAdminFetch();
  const { handleRemoveBook } = useBookAdminForm();

  const [isOpenBookModal, setIsOpenBookModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenRemovingBookModal, setIsOpenRemovingBookModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState<BookType | null>(null);

  const handleOpenEditingModal = (book: BookType) => {
    setIsOpenBookModal("edit");
    setSelectedBook(book);
  };

  const handleOpenDeletingModal = (book: BookType) => {
    setIsOpenRemovingBookModal(true);
    setSelectedBook(book);
  };

  const onDeleteBook = useCallback(
    async (bookId?: string) => {
      try {
        await handleRemoveBook(bookId);
      } catch (e) {
        error(e);
      } finally {
        setIsOpenRemovingBookModal(false);
      }
    },
    [handleRemoveBook, setIsOpenRemovingBookModal, error]
  );

  if (isBooksAdminLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <ItemsCounter
        itemsAmount={itemsAmount}
        setItemsAmount={setItemsAmount}
        setItemOffset={setItemOffset}
      />

      {books && books.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>
              <Trans>Photo</Trans>
            </div>
            <div>
              <Trans>Title</Trans>
            </div>
            <div>
              <Trans>Description</Trans>
            </div>
            <div>
              <Trans>Type</Trans>
            </div>
            <div>
              <Trans>Tags</Trans>
            </div>
            <div>
              <Trans>Price</Trans>
            </div>
            <div>
              <Trans>Discount Price</Trans>
            </div>
            <div>
              <Trans>Status</Trans>
            </div>
            <div></div> {/*For edit button */}
            <div></div> {/*For delete button */}
          </div>

          {books.map((book) => (
            <div className={styles.tableRowBody} key={book.id}>
              <div className={styles.imageWrapper}>
                <img src={getImageUrl(book.picture)} alt={book.title} />
              </div>
              <div>{book.title}</div>
              <div>{book.description}</div>
              <div>{book.category}</div>
              <div>{book.tags ? book.tags.join(", ") : null}</div>
              <div>{book.price}</div>
              <div>{book.discountPrice}</div>
              <div>{book.isPublished ? t(i18n)`Yes` : t(i18n)`No`}</div>
              <div className={styles.icon}>
                <EditIcon
                  color="white"
                  onClick={() => handleOpenEditingModal(book)}
                />
              </div>
              <div className={styles.icon}>
                <DeleteIcon onClick={() => handleOpenDeletingModal(book)} />
              </div>
            </div>
          ))}

          <Pagination
            amountOfItems={itemsAmount}
            length={total}
            currentPage={currentPage}
            setItemOffset={setItemOffset}
            setCurrentPage={setCurrentPage}
            variant="admin"
          />
        </div>
      ) : (
        <h4>
          <Trans>
            Unfortunately, information about the books is not available.
          </Trans>
        </h4>
      )}

      <BookAdminModal
        isOpen={isOpenBookModal}
        onClose={() => setIsOpenBookModal(null)}
        book={selectedBook}
      />

      <ConfirmModal
        isOpen={isOpenRemovingBookModal}
        modalTitle={t(i18n)`Delete product`}
        modalText={t(i18n)`Are you sure you want to delete this product?`}
        onClose={() => setIsOpenRemovingBookModal(false)}
        onSubmit={() => onDeleteBook(selectedBook?.id)}
      />
    </section>
  );
}
