import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { storageContext } from "../../../context/storage-provider/StorageProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// consts
import { INIT_BOOK_ADMIN_FORM } from "./BooksAdminScreen.consts";

//translations
import { t } from "@lingui/macro";

// types
import type {
  BookAdminFormType,
  BookType,
} from "../../../context/books-provider/BooksProvider.types";
import { ActionMeta } from "react-select";

export function useBookAdminForm(
  modalType?: "create" | "edit" | null,
  book?: BookType | null
) {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { addBook, updateBook, deleteBook } = useContext(booksContext);
  const { uploadFile, deleteFile, setUploadProgress, uploadImage } =
    useContext(storageContext);

  const pictureInputRef = useRef<any>(null);
  const bookInputRef = useRef<any>(null);

  const [bookAdminFormData, setBookAdminFormData] =
    useState<BookAdminFormType>(INIT_BOOK_ADMIN_FORM);

  useEffect(() => {
    if (book && modalType === "edit") {
      const {
        title,
        description,
        tags,
        category,
        author,
        price,
        discountPrice,
        pages,
        picture,
        pictures,
        source,
        isPublished,
      } = book;

      setBookAdminFormData({
        title,
        description,
        tags,
        category,
        author,
        price,
        discountPrice,
        pages,
        picture,
        pictures,
        source,
        isPublished,
      });
    } else {
      setBookAdminFormData(INIT_BOOK_ADMIN_FORM);
    }
  }, [book, modalType]);

  const handleChangeBookAdminData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      if (name === "discountPrice") {
        return setBookAdminFormData((prev) => ({
          ...prev,
          discountPrice: value ? value : null,
        }));
      }

      if (name === "isPublished") {
        return setBookAdminFormData((prev) => ({
          ...prev,
          isPublished: !prev.isPublished,
        }));
      }

      setBookAdminFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setBookAdminFormData]
  );

  const handleChangeSelectData = useCallback(
    (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      if (Array.isArray(newValue)) {
        const multiOptions = newValue as { label: string; value: string }[];

        return setBookAdminFormData((prev) => ({
          ...prev,
          [actionMeta.name || ""]: multiOptions.map(
            (multiOption) => multiOption.value
          ),
        }));
      }

      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setBookAdminFormData((prev) => ({
        ...prev,
        [actionMeta.name || ""]: option.value,
      }));
    },
    [setBookAdminFormData]
  );

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const file = acceptedFiles[0];

      const picture = await uploadImage(file);

      if (picture) {
        setBookAdminFormData((prev) => ({
          ...prev,
          picture: picture.file.key,
        }));
      }

      success(t(i18n)`File was successfully uploaded`);
    } catch (e) {
      error(e);
    }
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { "image/*": [] },
    });

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        // edit
        if (book && modalType === "edit") {
          await updateBook(book.id, bookAdminFormData);
          return success(t(i18n)`Book successfully edited.`);
        }

        // create
        await addBook(bookAdminFormData);
        success(t(i18n)`Book successfully created.`);
      } catch (e) {
        throw e;
      }
    },
    [bookAdminFormData, i18n, modalType, book, addBook, updateBook, success]
  );

  const handleRemoveBook = useCallback(
    async (bookId?: string) => {
      try {
        if (bookId) {
          await deleteBook(bookId);
          success(t(i18n)`The book has been successfully deleted`);
        }
      } catch (e) {
        throw e;
      }
    },
    [i18n, deleteBook, success]
  );

  const handleRemovePicture = () => {
    setBookAdminFormData((prev) => ({
      ...prev,
      picture: null,
    }));
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = bookInputRef.current.files[0] as File;

      const source = await uploadFile(file);

      if (source) {
        setBookAdminFormData((prev) => ({
          ...prev,
          source: source.file,
        }));
      }

      success(t(i18n)`File was successfully uploaded`);
    } catch (e) {
      error(e);
    }
  };

  const handleRemoveSource = async () => {
    if (bookAdminFormData.source?.key) {
      try {
        await deleteFile(bookAdminFormData.source.key);

        setBookAdminFormData((prev) => ({
          ...prev,
          source: null,
        }));

        setUploadProgress(0);

        success(t(i18n)`File was successfully deleted`);
      } catch (e) {
        error(e);
      }
    }
  };

  const isDisabledSubmitButton = useMemo(
    () =>
      !bookAdminFormData.title ||
      !bookAdminFormData.description ||
      !bookAdminFormData.category ||
      !bookAdminFormData.author ||
      !bookAdminFormData.price ||
      !bookAdminFormData.pages ||
      bookAdminFormData.title.length >= 64 ||
      bookAdminFormData.author.length >= 64,

    [bookAdminFormData]
  );

  const categoryBooksAdminOptions = useMemo(
    () => [
      { label: t(i18n)`Business`, value: "business" },
      { label: t(i18n)`Management`, value: "management" },
      { label: t(i18n)`Motivation`, value: "motivation" },
      { label: t(i18n)`Self-realization`, value: "self-realization" },
      { label: t(i18n)`Time management`, value: "Time management" },
    ],
    [i18n.locale]
  );

  const tagsBooksAdminOptions = useMemo(
    () => [
      { label: t(i18n)`Sponsored`, value: "sponsored" },
      { label: t(i18n)`Authored`, value: "authored" },
      { label: t(i18n)`Starred`, value: "starred" },
    ],
    [i18n.locale]
  );

  return {
    bookAdminFormData,
    pictureInputRef,
    bookInputRef,
    isDisabledSubmitButton,
    categoryBooksAdminOptions,
    tagsBooksAdminOptions,
    setBookAdminFormData,
    handleChangeBookAdminData,
    handleChangeSelectData,
    handleRemovePicture,
    handleRemoveBook,
    handleUpload,
    handleRemoveSource,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    onSubmit,
  };
}

export function useBooksAdminFetch() {
  const { error } = useContext(errorContext);
  const { getBooks } = useContext(booksContext);

  const [isBooksAdminLoading, setIsBooksAdminLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(10);

  const booksAdminFetch = async () => {
    try {
      setIsBooksAdminLoading(true);

      await getBooks(itemsAmount, itemOffset);
    } catch (err) {
      error(err);
    } finally {
      setIsBooksAdminLoading(false);
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsAmount]);

  return {
    isBooksAdminLoading,
    itemsAmount,
    currentPage,
    setCurrentPage,
    setItemsAmount,
    setItemOffset,
  };
}
