export const PATHS = {
  // general
  index: "/",
  books: "/books",
  book: "/books/:bookId",
  collections: "/collections",
  collection: "/collections/:collectionId",
  bookWithGenre: "/books/genre/:genre",
  cookie: "/cookie",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  reset: "/reset",
  failed: "/failed",
  paymentSuccess: "/payment/success",

  openBanking: "/open-banking/:orderId",
  transactionLoading: "transaction/ob/:transactionId",
  paymentFailed: "/payment/failed",

  // guest
  login: "/login",
  signup: "/signup",

  // user
  profile: "/profile",
  history: "/history",
  myBooks: "/my-books",
  myCollections: "/my-collections",

  // admin
  adminBooks: "/admin/books",
  adminCollections: "/admin/collections",
  adminCoupons: "/admin/coupons",
} as const;

export const routeControlsList: Array<string> = Object.keys(PATHS);
