// images
import introduction_books from "../../../../assets/images/introduction_books.png";
import books_src from "../../../../assets/images/books.png";
import hygge_book_src from "../../../../assets/images/hygge_book.png";

// icons
import { LogoWithUnderline } from "../../../../assets/icons/LogoWithUnderline";

//translations
import { Trans } from "@lingui/macro";

export const introductionItemsData = [
  {
    title: (
      <>
        <h2>
          <Trans>Fuel your</Trans>
        </h2>
        <h2>
          <Trans>motivation with</Trans>
        </h2>
        <LogoWithUnderline />
      </>
    ),
    description: (
      <>
        <span>
          <Trans>
            Fuel your motivation with our wide selection of books on cultivating
            self-esteem and setting goals for achieving success.
          </Trans>
        </span>
      </>
    ),
    img: <img src={introduction_books} alt="books on hand" />,
  },
  {
    title: (
      <>
        <h2>
          <Trans>Discover worlds</Trans>
        </h2>
        <h2>
          <Trans>within pages</Trans>
        </h2>
        <h2>
          at <LogoWithUnderline />
        </h2>
      </>
    ),
    description: (
      <>
        <span>
          <Trans>
            Dive into captivating stories, enrich your mind, and fuel your
            imagination with our curated collection. Experience the joy of
            discovery as you explore boundless realms through the power of
            books.
          </Trans>
        </span>
      </>
    ),
    img: <img src={books_src} alt="hygge book" />,
  },
  {
    title: (
      <>
        <h2>
          <Trans>Discover top</Trans>
        </h2>
        <h2>
          <Trans>rated books of</Trans>
        </h2>
        <h2>
          <Trans>last month</Trans>
        </h2>
      </>
    ),
    description: (
      <>
        <span>
          <Trans>
            Uncover the literary gems that captured readers' hearts with our
            selection of top-rated books from last month.Explore the titles that
            garnered rave reviews and discover your next must-read masterpiece.
          </Trans>
        </span>
      </>
    ),
    img: <img src={hygge_book_src} alt="books on hand" />,
  },
];
