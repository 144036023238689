import { SVGProps } from "react";

const CloseIcon = ({
  className,
  color = "#000",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.3438 20.1623L15.1813 11.9998L23.3438 3.8373C24.225 2.95605 24.225 1.5373 23.3438 0.656055C22.4625 -0.225195 21.0438 -0.225195 20.1625 0.656055L12 8.81855L3.83755 0.656055C2.9563 -0.225195 1.53755 -0.225195 0.656299 0.656055C-0.224951 1.5373 -0.224951 2.95605 0.656299 3.8373L8.8188 11.9998L0.656299 20.1623C-0.224951 21.0436 -0.224951 22.4623 0.656299 23.3436C1.53755 24.2248 2.9563 24.2248 3.83755 23.3436L12 15.1811L20.1625 23.3436C21.0438 24.2248 22.4625 24.2248 23.3438 23.3436C24.2188 22.4623 24.2188 21.0373 23.3438 20.1623Z"
        fill={color}
      />
    </svg>
  );
};
export { CloseIcon };
