import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

//translations
import { t } from "@lingui/macro";

export function useBooksFetch() {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { getBooksByGenre, getBooks } = useContext(booksContext);

  const { genre } = useParams();

  const [isBooksLoading, setIsBooksLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const booksAdminFetch = async () => {
    try {
      setIsBooksLoading(true);

      await getBooks(30, itemOffset, true);

      if (genre) {
        const category =
          genre === "time-management" ? "Time management" : genre;

        await getBooksByGenre(category, 30, itemOffset);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsBooksLoading(false);
    }
  };

  const bookTitle = () => {
    switch (genre) {
      case "business":
        return t(i18n)`business`;

      case "management":
        return t(i18n)`management`;

      case "self-realization":
        return t(i18n)`self realization`;

      case "motivation":
        return t(i18n)`motivation`;

      case "time-management":
        return t(i18n)`time management`;
      default:
        return "";
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, genre]);

  useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [genre]);

  return {
    isBooksLoading,
    currentPage,
    setCurrentPage,
    setItemOffset,
    bookTitle,
  };
}
