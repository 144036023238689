import { useCallback, useContext, useState } from "react";

// translations
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

// components
import Spinner from "../../../components/spinner/Spinner";
import { ItemsCounter } from "../../../components/items-counter/ItemsCounter";
import { Pagination } from "../../../components/pagination/Pagination";
import { CouponAdminModal } from "./CouponAdminModal/CouponAdminModal";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";

// icons
import { EditIcon } from "../../../assets/icons/EditIcon";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";

// hooks
import { useCouponAdminForm, useCouponsAdminFetch } from "./use-coupons-admin";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { couponContext } from "../../../context/coupon-provider/CouponProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import type { CouponType } from "../../../context/coupon-provider/CouponsProvider.types";

// styles
import styles from "./CouponsAdmin.module.scss";

export function CouponsAdminScreen() {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { couponsData, total } = useContext(couponContext);

  const {
    isCouponsLoading,
    itemsAmount,
    currentPage,
    setCurrentPage,
    setItemsAmount,
    setItemOffset,
  } = useCouponsAdminFetch();
  const { handleRemoveCoupon } = useCouponAdminForm();

  const [isOpenCouponModal, setIsOpenCouponModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenRemovingCouponModal, setIsOpenRemovingCouponModal] =
    useState(false);

  const [selectedCoupon, setSelectedCoupon] = useState<CouponType | null>(null);

  const handleOpenEditingModal = (coupon: CouponType) => {
    setIsOpenCouponModal("edit");
    setSelectedCoupon(coupon);
  };

  const handleOpenDeletingModal = (coupon: CouponType) => {
    setIsOpenRemovingCouponModal(true);
    setSelectedCoupon(coupon);
  };

  const onDeleteCoupon = useCallback(
    async (couponId?: string) => {
      try {
        await handleRemoveCoupon(couponId);
      } catch (e) {
        error(e);
      } finally {
        setIsOpenRemovingCouponModal(false);
      }
    },
    [handleRemoveCoupon, setIsOpenRemovingCouponModal, error]
  );

  if (isCouponsLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <ItemsCounter
        itemsAmount={itemsAmount}
        setItemsAmount={setItemsAmount}
        setItemOffset={setItemOffset}
      />

      {couponsData && couponsData.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>
              <Trans>Code</Trans>
            </div>
            <div>
              <Trans>Description</Trans>
            </div>
            <div>
              <Trans>Discount</Trans>
            </div>
            <div>
              <Trans>Activated</Trans>
            </div>
            <div></div> {/*For edit button */}
            <div></div> {/*For delete button */}
          </div>

          {couponsData.map((coupon) => (
            <div className={styles.tableRowBody} key={coupon.id}>
              <div>{coupon.code}</div>
              <div>{coupon.description}</div>
              <div>{coupon.discount}</div>
              <div>{coupon.isActivated ? t(i18n)`Yes` : t(i18n)`No`}</div>
              <div className={styles.icon}>
                <EditIcon
                  color="white"
                  onClick={() => handleOpenEditingModal(coupon)}
                />
              </div>
              <div className={styles.icon}>
                <DeleteIcon onClick={() => handleOpenDeletingModal(coupon)} />
              </div>
            </div>
          ))}

          <Pagination
            amountOfItems={itemsAmount}
            length={total}
            currentPage={currentPage}
            setItemOffset={setItemOffset}
            setCurrentPage={setCurrentPage}
            variant="admin"
          />
        </div>
      ) : (
        <h4>
          <Trans>
            Unfortunately, information about the books is not available.
          </Trans>
        </h4>
      )}

      <CouponAdminModal
        isOpen={isOpenCouponModal}
        onClose={() => setIsOpenCouponModal(null)}
        coupon={selectedCoupon}
      />

      <ConfirmModal
        isOpen={isOpenRemovingCouponModal}
        modalTitle={t(i18n)`Delete coupon`}
        modalText={t(i18n)`Are you sure you want to delete this coupon?`}
        onClose={() => setIsOpenRemovingCouponModal(false)}
        onSubmit={() => onDeleteCoupon(selectedCoupon?.id)}
      />
    </section>
  );
}
