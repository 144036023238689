import { useCallback, useContext, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";

// translations
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

// hooks
import { useCartModalOrder } from "./use-cart-modal-order";

// components
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import Input from "../../input/Input";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// types
import type { CollectionType } from "../../../context/collections-provider/CollectionsProvider.types";
import type { BookType } from "../../../context/books-provider/BooksProvider.types";

// styles
import styles from "./CartModalOrder.module.scss";

type CartModalOrderProps = {
  items: number;
  filteredCart: {
    books: BookType[];
    collections: CollectionType[];
  };
  total: string;
  onCloseModal: () => void;
};

export function CartModalOrder({
  items,
  filteredCart,
  total,
  onCloseModal,
}: CartModalOrderProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const {
    couponCode,
    couponDiscountPrice,
    isDisabledSubmitButton,
    handleChangeCouponAdminData,
    onSubmit,
  } = useCartModalOrder();

  const [isConfirmedTerms, setIsConfirmedTerms] = useState(false);

  const handleCheckout = useCallback(
    async (type: "securePayCard" | "openBanking") => {
      try {
        const booksIds = filteredCart.books.map((book) => book.id);

        const collectionsIds = filteredCart.collections.map(
          (collection) => collection.id
        );

        const orderData = await createOrder(
          booksIds,
          collectionsIds,
          couponCode ? couponCode : undefined
        );

        if (orderData) {
          if (type === "openBanking") {
            const generatedLink = generatePath(PATHS.openBanking, {
              orderId: orderData.id,
            });

            onCloseModal();

            return navigate(generatedLink);
          }

          const totalWithDiscountPrice =
            couponDiscountPrice && couponCode
              ? (
                  Number(total) -
                  Number(total) * (couponDiscountPrice / 100)
                ).toFixed(2)
              : total;

          await checkoutPayment(orderData.id, totalWithDiscountPrice);
        }
      } catch (e) {
        error(e);
      }
    },
    [checkoutPayment, total, filteredCart, couponCode, couponDiscountPrice]
  );

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>
          <Trans>Order Summary</Trans>
        </h6>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <p>
            <Trans>Subtotal</Trans> ({items}):
          </p>
          <p>
            {getCurrencySymbol()}
            {total}
          </p>
        </div>

        <div className={styles.couponWrapper}>
          <Input
            placeholder={t(i18n)`Enter coupon`}
            value={couponCode}
            onChange={handleChangeCouponAdminData}
          />{" "}
          <Button
            className={styles.couponButton}
            onClick={onSubmit}
            disabled={isDisabledSubmitButton}
          >
            <Trans>Check coupon</Trans>
          </Button>
        </div>

        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="terms_and_conditions"
            name="terms"
            value="1"
            checked={isConfirmedTerms}
            onChange={() => setIsConfirmedTerms((prev) => !prev)}
          />

          <p className={styles.checkboxText}>
            <Trans>I have read and agree with</Trans>{" "}
            <Link to={PATHS.termsAndConditions} onClick={onCloseModal}>
              <Trans>Terms of service</Trans>
            </Link>{" "}
            <Trans>and</Trans>{" "}
            <Link to={PATHS.privacyPolicy} onClick={onCloseModal}>
              <Trans>Privacy policy</Trans>
            </Link>
          </p>
        </div>

        <div className={styles.total}>
          <p>
            <Trans>Total</Trans>
          </p>
          {couponDiscountPrice ? (
            <p>
              {getCurrencySymbol()}
              {total} - {couponDiscountPrice}% = {getCurrencySymbol()}
              {(
                Number(total) -
                Number(total) * (couponDiscountPrice / 100)
              ).toFixed(2)}
            </p>
          ) : (
            <p>
              {getCurrencySymbol()}
              {total}
            </p>
          )}
        </div>
        <div className={styles.separator} />
        <div className={styles.buttonsWrapper}>
          <Button
            onClick={() => handleCheckout("securePayCard")}
            disabled={!isConfirmedTerms}
          >
            <Trans>Secure Checkout</Trans>
          </Button>
          <Button
            onClick={() => handleCheckout("openBanking")}
            disabled={!isConfirmedTerms}
          >
            <Trans>Pay via bank</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
}
