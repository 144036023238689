import React, { useMemo } from "react";

// component
import SelectReact from "react-select";

// types
import type { StylesConfig } from "react-select/dist/declarations/src/styles";
import type { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

/**
 * react-select styled for different themes.
 * used for synchronous operations, there is a built-in search by options.
 *
 * @param restParam (all props that are available for react select)
 * @returns JSX.Element} component CustomReactSelect.
 */
export function Select({ ...restParam }: StateManagerProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customStyles: StylesConfig<any> = useMemo(
    () => ({
      input: (provided) => ({
        ...provided,
        width: "fit-content",
        color: "#000000",
        margin: 0,
        padding: 0,
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: "12px 18px",
      }),

      control: (provided) => ({
        ...provided,
        background: "transparent",
        border: `1px solid #dadada`,
        borderRadius: "5px",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        lineHeight: "20px",
        cursor: "pointer",
      }),

      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        backgroundColor: "gray",
        bottom: "40px",
        top: "auto",
      }),

      menuList: (provided) => ({
        ...provided,
        maxHeight: "180px",
      }),

      option: (provided, { isDisabled, isFocused }) => ({
        ...provided,
        paddingLeft: isDisabled ? "6px" : "18px",
        cursor: isDisabled ? "not-allowed" : "default",
        textTransform: isDisabled ? "uppercase" : undefined,
        backgroundColor: isFocused ? "rgba(255,255,255, 0.5)" : "gray",
        color: "#FFF",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
      }),

      multiValue: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#000000",
        margin: 0,
      }),

      multiValueLabel: (provided) => ({
        ...provided,
        color: "#000000",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        padding: 0,
      }),

      placeholder: (provided) => ({
        ...provided,
        color: "#9b9ea6",
      }),

      indicatorsContainer: (provided) => ({
        ...provided,
        padding: "0 14px",
      }),

      // To reset default styles
      indicatorSeparator: () => ({}),
    }),
    []
  );

  return <SelectReact styles={customStyles} {...restParam} />;
}
