import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { apiContext } from "../api-provider/ApiProvider";
import { sessionContext } from "../session-provider/SessionProvider";
import { localesContext } from "../local-provider/LocalProvider";
import { currencyContext } from "../currency-provider/CurrencyProvider";

// consts
import {
  API_URL_AUTH,
  API_URL_COURSE,
  API_URL_FEEDBACK,
  API_URL_PROFILE,
  API_URL_RESET_PASSWORD,
  API_URL_USER,
  API_URL_USER_NEW_PASSWORD,
} from "./UserProvider.consts";
import { PATHS } from "../../route/route.controls";

// schemas
import { allUsersSchema, profileSchema } from "./UserProvider.schemas";
import { loginSchema } from "../session-provider/SessionProvider.schemas";

// helpers
import { handleErrorWithMessages } from "../error-provider/error";

//translations
import { t } from "@lingui/macro";

// types
import type {
  ChangePasswordFormType,
  CourseFormType,
  FeedbackFormType,
  ProfileType,
  ResetPasswordFormType,
  SignUpUserType,
  UpdatedProfileFormType,
  UserContext,
  UserProviderProps,
} from "./UserProvider.types";

export const userContext = React.createContext({} as UserContext);

export const UserProvider: FunctionComponent<UserProviderProps> = (props) => {
  const { i18n } = useContext(localesContext);
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);
  const { setSessionCookie, setRefreshSessionCookie, removeSessionCookie } =
    useContext(sessionContext);

  const navigate = useNavigate();

  const { children } = props;

  const [users, setUsers] = useState<ProfileType[] | null>(null);
  const [userData, setUserData] = useState<ProfileType | null>(null);

  const signUp = async (signUpUser: SignUpUserType) => {
    try {
      await api(API_URL_AUTH, {
        method: "POST",
        data: { ...signUpUser },
      });
    } catch (error) {
      throw error;
    }
  };

  const logIn = async (
    loginUser: Pick<SignUpUserType, "email" | "password">
  ) => {
    try {
      const authData = await api(
        API_URL_AUTH,
        {
          method: "PUT",
          data: { email: loginUser.email, password: loginUser.password },
        },
        loginSchema
      );

      if (authData) {
        setSessionCookie(authData.accessToken, authData.accessTokenExpiresIn);
        setRefreshSessionCookie(
          authData.refreshToken,
          authData.refreshTokenExpiresIn
        );

        await getProfile();
      }
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "UserNotFoundException: User not found": t(
          i18n
        )`User not found. Please register a new one.`,
        "UnauthorizedException: Invalid credentials": t(
          i18n
        )`Incorrect email or password entered. Please check your details and try again.`,
      });
    }
  };

  const logOut = async () => {
    try {
      await api(API_URL_AUTH, { method: "DELETE" });

      removeSessionCookie();

      navigate(PATHS.index);
    } catch (error) {
      throw error;
    }
  };

  const getProfile = async () => {
    try {
      const currentCurrency = getCurrency();
      const userData = await api(
        `${API_URL_PROFILE}?currency=${currentCurrency}`,
        {},
        profileSchema
      );

      setUserData(userData);
      return userData;
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = async (formData: UpdatedProfileFormType) => {
    try {
      if (userData) {
        const currentCurrency = getCurrency();

        const updatedProfile = await api(
          `${API_URL_PROFILE}?currency=${currentCurrency}`,

          { method: "PUT", data: formData },
          profileSchema
        );

        setUserData(updatedProfile);
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      const allUsers = await api(API_URL_USER, {}, allUsersSchema);

      if (allUsers) {
        setUsers(allUsers.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateUserById = async (userId: string, role: "user" | "admin") => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "PUT", data: { role } });
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "DELETE" });
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (formData: ResetPasswordFormType) => {
    try {
      await api(API_URL_RESET_PASSWORD, {
        method: "PUT",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  const changePassword = async (formData: ChangePasswordFormType) => {
    try {
      await api(API_URL_USER_NEW_PASSWORD, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  const feedback = async (formData: FeedbackFormType) => {
    try {
      await api(API_URL_FEEDBACK, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "email must be an email": t(i18n)`Email must be an email.`,
        "firstName must be longer than or equal to 3 characters": t(
          i18n
        )`FirstName must be longer than or equal to 3 characters.`,
        "lastName must be longer than or equal to 3 characters": t(
          i18n
        )`LastName must be longer than or equal to 3 characters.`,
        "phone must match /^\\+[1-9]\\d{1,14}$/ regular expression": t(
          i18n
        )`Phone number must start with a '+' followed by 2 to 15 digits, with no spaces or special characters.`,
        "message must be longer than or equal to 3 characters": t(
          i18n
        )`Message must be longer than or equal to 3 characters.`,
      });
    }
  };

  const teacherSkillsUp = async (formData: CourseFormType) => {
    try {
      await api(API_URL_COURSE, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      users,
      userData,
      signUp,
      logIn,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      resetPassword,
      changePassword,
      feedback,
      teacherSkillsUp,
    }),
    [
      users,
      userData,
      signUp,
      logIn,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      resetPassword,
      changePassword,
      feedback,
      teacherSkillsUp,
    ]
  );

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
};
