import React from "react";
import ReactPaginate from "react-paginate";
import classnames from "classnames";

// icons
import { PaginationArrowIcon } from "../../assets/icons/PaginationArrow";

// styles
import styles from "./Pagination.module.scss";

//translations
import { Trans } from "@lingui/macro";

type PaginationProps<T> = {
  variant?: "admin" | "user";
  amountOfItems: number;
  length: number;
  currentPage?: number;
  setItemOffset: React.Dispatch<React.SetStateAction<number>>;
  pageOnClick?: () => void;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
};

export function Pagination<T>({
  variant = "user",
  amountOfItems,
  length,
  currentPage,
  setCurrentPage,
  setItemOffset,
  pageOnClick,
}: PaginationProps<T>) {
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = amountOfItems;
  const pageCount = Math.ceil(length / itemsPerPage);

  const page = currentPage ? currentPage + 1 : 1;

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % length;

    setItemOffset(newOffset);
    if (setCurrentPage) {
      setCurrentPage(event.selected);
    }

    if (pageOnClick) {
      pageOnClick();
    }
  };

  const isUser = variant === "user";

  return (
    <div className={styles.container}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <div
            className={classnames(
              styles.arrow,
              isUser ? styles.paginationArrow : styles.adminPaginationArrow
            )}
          >
            <p>
              <Trans>Next</Trans>
            </p>
            <PaginationArrowIcon
              color={
                isUser
                  ? currentPage === pageCount
                    ? "#858585"
                    : "#111726"
                  : currentPage === pageCount
                  ? "#858585"
                  : "#ffffff"
              }
            />
          </div>
        }
        onPageChange={handlePageClick}
        pageLinkClassName={classnames(
          styles.link,
          isUser ? styles.pageLink : styles.adminPageLink
        )}
        activeLinkClassName={classnames(
          styles.activeLink,
          isUser ? styles.activeLinkClassName : styles.adminActiveLinkClassName
        )}
        pageRangeDisplayed={5}
        disabledLinkClassName={styles.disableArrow}
        forcePage={currentPage}
        pageCount={pageCount}
        className={styles.pagination}
        previousLabel={
          <div
            className={classnames(
              styles.arrow,
              isUser ? styles.paginationArrow : styles.adminPaginationArrow
            )}
          >
            <PaginationArrowIcon
              className={styles.previousArrow}
              color={
                isUser
                  ? currentPage === 1
                    ? "#858585"
                    : "#111726"
                  : currentPage === 1
                  ? "#858585"
                  : "#ffffff"
              }
            />
            <p>
              <Trans>Prev</Trans>
            </p>
          </div>
        }
        renderOnZeroPageCount={null}
      />
      <p className={styles.paginationDescription}>
        <Trans>Showing</Trans>{" "}
        {itemsPerPage < length ? (page - 1) * itemsPerPage + 1 : length}-
        {Math.min(page * itemsPerPage, length)} <Trans>of</Trans> {length}{" "}
        <Trans>results</Trans>
      </p>
    </div>
  );
}
