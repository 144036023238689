import { Link } from "react-router-dom";

// consts
import { PATHS } from "../../route/route.controls";

//context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function useAsideConsts() {
  const { i18n } = useContext(localesContext);
  const footerData = [
    {
      title: t(i18n)`Contact us`,
      items: [
        <a>
          <Trans>Company name: Global Stride LTD</Trans>
        </a>,
        <a>
          <Trans>
            Address: 85 GREAT PORTLAND STREET FIRST FLOOR, LONDON, ENGLAND, W1W
            7LT
          </Trans>
        </a>,
        <a href="mailto:support@thrivestore.co.uk">
          support@thrivestore.co.uk
        </a>,
        <a href="tel:+441514570426">+441514570426</a>,
        <Trans>9:00 - 18:00 (Mon - Fri)</Trans>,
      ],
    },

    {
      title: t(i18n)`Politics`,
      items: [
        <Link to={PATHS.cookie}>
          <Trans>Cookie</Trans>
        </Link>,
        <Link to={PATHS.privacyPolicy}>
          <Trans>Privacy Policy</Trans>
        </Link>,
        <Link to={PATHS.termsAndConditions}>
          <Trans>Terms and conditions</Trans>
        </Link>,
      ],
    },
  ];

  return {
    footerData,
  };
}
