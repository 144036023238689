import { SVGProps } from "react";

const BankIcon = ({
  className,
  color = "#FDFDFD",
  width = 18,
  height = 19,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity=".7" fill={color}>
        <path d="m17.24 5.944-7.934-4.91a.582.582 0 0 0-.612 0L.76 5.945a.55.55 0 0 0-.224.268.526.526 0 0 0-.013.344.546.546 0 0 0 .204.283c.098.071.217.11.34.11h15.866a.581.581 0 0 0 .34-.11c.098-.07.17-.17.204-.283a.527.527 0 0 0-.013-.344.55.55 0 0 0-.224-.268ZM4.9 7.948H3.1c-.331 0-.6.224-.6.5v5c0 .276.269.5.6.5h1.8c.331 0 .6-.224.6-.5v-5c0-.276-.269-.5-.6-.5ZM9.9 7.948H8.1c-.331 0-.6.224-.6.5v5c0 .276.269.5.6.5h1.8c.331 0 .6-.224.6-.5v-5c0-.276-.269-.5-.6-.5ZM14.9 7.948h-1.8c-.331 0-.6.224-.6.5v5c0 .276.269.5.6.5h1.8c.331 0 .6-.224.6-.5v-5c0-.276-.269-.5-.6-.5ZM15.857 14.948H2.143c-.631 0-1.143.537-1.143 1.2v.6c0 .663.512 1.2 1.143 1.2h13.714c.631 0 1.143-.537 1.143-1.2v-.6c0-.662-.512-1.2-1.143-1.2Z" />
      </g>
    </svg>
  );
};
export { BankIcon };
